import React from 'react';
import { useQuery } from '@apollo/client';
import { MY_TRIP_PARTICIPATIONS_QUERY } from '../../../../graphql/trip-participants';
import {
  MyTripParticipationsQuery,
  MyTripParticipationsQueryVariables,
  ParticipantStatusEnum,
  TripParticipant,
} from '../../../../generated/graphql';
import { Box, CircularProgress, Alert, Typography, Grid2 as Grid } from '@mui/material';
import { DateTime } from 'luxon';
import { TripCard } from './TripCard';

const MyTripsSection: React.FC = () => {
  const { data, loading, error } = useQuery<MyTripParticipationsQuery, MyTripParticipationsQueryVariables>(
    MY_TRIP_PARTICIPATIONS_QUERY,
    { fetchPolicy: 'network-only' }
  );

  if (loading) {
    return (
      <Box textAlign="center" mt={2}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return <Alert severity="error">Error loading your trip participations.</Alert>;
  }
  const participations = data?.myTripParticipations || [];

  const pendingTrips = participations.filter((p) => p.status === ParticipantStatusEnum.Pending && p.trip);
  const cancelledTrips = participations.filter(
    (p) => p.status === ParticipantStatusEnum.Cancelled || p.status === ParticipantStatusEnum.CancelledByParticipant
  );
  const approved = participations.filter((p) => p.status === ParticipantStatusEnum.Approved);
  const now = DateTime.now();
  const upcomingTrips = approved.filter((p) => DateTime.fromISO(p.trip!.endDate) > now);
  const pastTrips = approved.filter((p) => DateTime.fromISO(p.trip!.endDate) <= now);

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
        My Trips
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        Manage and explore all your upcoming, past, or pending trips below.
      </Typography>

      {upcomingTrips.length > 0 && (
        <>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Upcoming Trips
          </Typography>
          <Grid container spacing={2}>
            {upcomingTrips?.map((participation, index) => (
              <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                <TripCard key={'upcoming-' + index} participation={participation as TripParticipant} />
              </Grid>
            ))}
          </Grid>
        </>
      )}

      {pendingTrips.length > 0 && (
        <>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Pending Trips
          </Typography>
          <Grid container spacing={2}>
            {pendingTrips?.map((participation, index) => (
              <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                <TripCard key={'pending-' + index} participation={participation as TripParticipant} />
              </Grid>
            ))}
          </Grid>
        </>
      )}

      {pastTrips.length > 0 && (
        <>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Past Trips
          </Typography>
          <Grid container spacing={2}>
            {pastTrips?.map((participation, index) => (
              <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                <TripCard key={'past-' + index} participation={participation as TripParticipant} />
              </Grid>
            ))}
          </Grid>
        </>
      )}

      {cancelledTrips.length > 0 && (
        <>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Cancelled Trips
          </Typography>
          <Grid container spacing={2}>
            {cancelledTrips?.map((participation, index) => (
              <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                <TripCard key={'cancelled-' + index} participation={participation as TripParticipant} />
              </Grid>
            ))}
          </Grid>
        </>
      )}

      {participations.length === 0 && (
        <Alert severity="info" sx={{ mt: 2 }}>
          You have no trip participations yet.
        </Alert>
      )}
    </Box>
  );
};

export default MyTripsSection;
