import React, { useState, useEffect } from 'react';
import { Container, Box, Button, Alert, Typography, Paper, Snackbar, Grid2 } from '@mui/material';
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { TRIP_QUERY } from '../../graphql/trips';
import {
  MY_PARTICIPATION,
  PUBLIC_TRIP_PARTICIPANTS_QUERY,
  MY_TRIP_PAYMENTS,
  CANCEL_MY_PARTICIPATION_MUTATION,
} from '../../graphql/trip-participants';
import {
  TripQuery,
  TripQueryVariables,
  PublicTripParticipantsQuery,
  PublicTripParticipantsQueryVariables,
  PublicUser,
  MeetingDetail,
  ExtraInfo,
  Trip,
  TripParticipantPayment,
  MyParticipationQuery,
  MyParticipationQueryVariables,
  PaymentStatusEnum,
  CancellationPolicy,
} from '../../generated/graphql';

import ReservationSummary from '../../components/TripReservationDetails/ReservationSummary';
import PaymentDetails from '../../components/TripReservationDetails/PaymentDetails';
import ParticipantInformation from '../../components/TripReservationDetails/ParticipantInformation';
import ItinerarySection from '../../components/TripReservationDetails/ItinerarySection';
import MeetingDetails from '../../components/TripReservationDetails/MeetingDetails';
import ReservationAdditionalInformation from '../../components/TripReservationDetails/ReservationAdditionalInformation';
import MessagesSection from '../../components/TripReservationDetails/MessagesSection';
import CancellationDetails from '../../components/TripReservationDetails/CancellationDetails';
import CancelReservationModal from '../../components/TripReservationDetails/CancelReservationModal';
import { DateTime } from 'luxon';

export type SectionKey =
  | 'summary'
  | 'payment'
  | 'participant'
  | 'itinerary'
  | 'meeting'
  | 'extra'
  | 'cancellation'
  | 'messages';

const menuItems: { key: SectionKey; label: string }[] = [
  { key: 'summary', label: 'Trip Summary' },
  { key: 'payment', label: 'Payment Details' },
  { key: 'participant', label: 'Who is coming?' },
  { key: 'itinerary', label: 'Itinerary' },
  { key: 'meeting', label: 'Meeting Details' },
  { key: 'extra', label: 'Additional Information' },
  { key: 'cancellation', label: 'Cancellation' },
  // { key: 'messages', label: 'Messages' }, // Uncomment when ready
];

const TripReservationDetailsPage: React.FC = () => {
  const { tripId } = useParams<{ tripId: string }>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const initialSection = (searchParams.get('section') as SectionKey) || 'summary';
  const [selectedSection, setSelectedSection] = useState<SectionKey>(initialSection);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  // Fetch trip details immediately.
  const { data, loading, error } = useQuery<TripQuery, TripQueryVariables>(TRIP_QUERY, {
    variables: { id: tripId! },
  });

  // Fetch my participation details.
  const { data: myParticipation } = useQuery<MyParticipationQuery, MyParticipationQueryVariables>(MY_PARTICIPATION, {
    variables: { tripId: tripId! },
  });

  // Lazy load participants when "participant" section is selected.
  const [loadParticipants, { data: participantsData, loading: participantsLoading, error: participantsError }] =
    useLazyQuery<PublicTripParticipantsQuery, PublicTripParticipantsQueryVariables>(PUBLIC_TRIP_PARTICIPANTS_QUERY, {
      variables: { tripId: tripId! },
      fetchPolicy: 'network-only',
    });

  // Lazy load payments when "payment" section is selected.
  const [loadPayments, { data: paymentsData, loading: paymentsLoading, error: paymentsError }] = useLazyQuery<
    { myTripPayments: TripParticipantPayment[] },
    { tripId: string }
  >(MY_TRIP_PAYMENTS, {
    variables: { tripId: tripId! },
    fetchPolicy: 'network-only',
  });

  // Mutation to cancel participation.
  const [cancelParticipation] = useMutation(CANCEL_MY_PARTICIPATION_MUTATION, {
    onCompleted: () => {
      setSnackbarMsg('Your reservation has been cancelled.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setCancelModalOpen(false);
      // Optionally refetch data if needed.
    },
    onError: (err) => {
      setSnackbarMsg(err.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setCancelModalOpen(false);
    },
  });

  // Trigger lazy queries when their section is selected.
  useEffect(() => {
    if (selectedSection === 'participant' && !participantsData && !participantsLoading) {
      loadParticipants();
    }
    if (selectedSection === 'payment' && !paymentsData && !paymentsLoading) {
      loadPayments();
    }
  }, [
    selectedSection,
    loadParticipants,
    participantsData,
    participantsLoading,
    loadPayments,
    paymentsData,
    paymentsLoading,
  ]);

  if (loading) {
    return (
      <Container sx={{ mt: 4 }}>
        <Typography align="center">Loading trip details…</Typography>
      </Container>
    );
  }
  if (error || !data?.trip) {
    return (
      <Container sx={{ mt: 4 }}>
        <Alert severity="error">{error?.message || 'Trip not found'}</Alert>
      </Container>
    );
  }

  const trip = data.trip;
  const payments = paymentsData?.myTripPayments || [];
  const totalPaid = payments.reduce((sum, p) => (p.status === PaymentStatusEnum.Paid ? sum + p.amount : sum), 0);

  // Compute cancellation refund info.
  const today = DateTime.now();
  const tripStart = DateTime.fromISO(trip.startDate);
  const daysBefore = Math.floor(tripStart.diff(today, 'days').days);
  let applicableRule = null;
  if (trip.cancellationPolicy && trip.cancellationPolicy.rules?.length) {
    const sortedRules = [...trip.cancellationPolicy.rules].sort((a, b) => b.daysBeforeStart - a.daysBeforeStart);
    applicableRule =
      sortedRules.find((rule) => daysBefore >= rule.daysBeforeStart) || sortedRules[sortedRules.length - 1];
  }
  const baseFee = trip.cancellationPolicy?.baseFee || 0;
  const refundPercentage = applicableRule ? applicableRule.refundPercentage : 0;
  const myFinalPrice = myParticipation?.myParticipation.finalPrice as number;
  const refundAmount = Math.max((myFinalPrice - baseFee) * (refundPercentage / 100), 0);
  const nonRefundable = myFinalPrice - refundAmount;

  // Render section content based on selected menu item.
  const renderSection = () => {
    switch (selectedSection) {
      case 'summary':
        return <ReservationSummary trip={trip as Trip} />;
      case 'payment':
        return paymentsLoading ? (
          <Typography>Loading payments…</Typography>
        ) : paymentsError ? (
          <Alert severity="error">{paymentsError.message}</Alert>
        ) : (
          <PaymentDetails finalPrice={myFinalPrice} paidAmount={totalPaid} />
        );
      case 'participant':
        return participantsLoading ? (
          <Typography>Loading participants…</Typography>
        ) : participantsError ? (
          <Alert severity="error">{participantsError.message}</Alert>
        ) : (
          <ParticipantInformation participants={participantsData?.publicTripParticipants as PublicUser[]} />
        );
      case 'itinerary':
        return <ItinerarySection itinerary={trip.itinerary} />;
      case 'meeting':
        return <MeetingDetails meetingDetail={trip.meetingDetail as MeetingDetail} />;
      case 'extra':
        return <ReservationAdditionalInformation extraInformation={trip.extraInformation as ExtraInfo[]} />;
      case 'cancellation':
        return (
          <Box>
            <CancellationDetails
              cancellationPolicy={trip.cancellationPolicy as CancellationPolicy}
              tripPrice={myFinalPrice}
              startDate={trip.startDate}
              onCancel={() => setCancelModalOpen(true)}
            />
            <CancelReservationModal
              open={cancelModalOpen}
              onClose={() => setCancelModalOpen(false)}
              onConfirm={() =>
                cancelParticipation({
                  variables: { input: { tripId: trip.id, reason: 'User initiated cancellation' } },
                })
              }
              refundInfo={{
                baseFee,
                refundPercentage,
                refundAmount,
                nonRefundable,
              }}
            />
          </Box>
        );
      case 'messages':
        return <MessagesSection />;
      default:
        return null;
    }
  };

  const handleSnackbarClose = (event: React.SyntheticEvent<Element, Event> | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Reservation Details
      </Typography>
      <Grid2 container spacing={4}>
        {/* Left Sidebar Navigation */}
        <Grid2 size={{ xs: 12, md: 4 }}>
          <Paper sx={{ p: 2 }}>
            <List>
              {menuItems.map((item) => (
                <ListItem key={item.key} disablePadding>
                  <ListItemButton selected={selectedSection === item.key} onClick={() => setSelectedSection(item.key)}>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid2>
        {/* Right Content Area */}
        <Grid2 size={{ xs: 12, md: 8 }}>
          <Paper sx={{ p: 3 }}>{renderSection()}</Paper>
        </Grid2>
      </Grid2>
      <Box sx={{ mt: 3, textAlign: 'center' }}>
        <Button variant="outlined" onClick={() => navigate(-1)} sx={{ textTransform: 'none' }}>
          Back
        </Button>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={(e) => handleSnackbarClose(e, 'timeout')} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default TripReservationDetailsPage;
