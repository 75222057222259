import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { MY_EVENTS_BOOKINGS, CANCEL_MY_EVENT_MUTATION } from '../../graphql/events';
import {
  MyEventBookingsQuery,
  MyEventBookingsQueryVariables,
  CancelMyEventMutation,
  CancelMyEventMutationVariables,
  EventBookingStatusEnum,
} from '../../generated/graphql';

const MyUpcomingEventsSection: React.FC = () => {
  const navigate = useNavigate();
  const { data, loading, error, fetchMore, refetch } = useQuery<MyEventBookingsQuery, MyEventBookingsQueryVariables>(
    MY_EVENTS_BOOKINGS,
    {
      variables: { first: 10 },
      fetchPolicy: 'network-only',
    }
  );

  const [cancelMyEvent] = useMutation<CancelMyEventMutation, CancelMyEventMutationVariables>(CANCEL_MY_EVENT_MUTATION, {
    onCompleted: () => refetch(),
  });

  // Local state for cancellation dialog and reason
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState<any>(null);
  // const [cancelReason, setCancelReason] = useState('');

  if (loading) {
    return (
      <Box textAlign="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  // Extract booking nodes from the connection
  const bookings = data?.myEventBookings?.edges?.map((edge) => edge.node) || [];

  // Filter upcoming events (using the event's endDate)
  const upcomingBookings = bookings.filter((booking) => {
    if (!booking || !booking.event) return false;
    return DateTime.fromISO(booking.event.endDate.toString()) > DateTime.now();
  });

  // Date formatting: if start and end are on the same day, show one day with start & end times
  const formatDateRange = (startISO: string, endISO: string): string => {
    const start = DateTime.fromISO(startISO);
    const end = DateTime.fromISO(endISO);
    if (start.hasSame(end, 'day')) {
      // Same day: show date once with start time and end time
      return `${start.toLocaleString(DateTime.DATE_MED)} ${start.toFormat('HH:mm')} – ${end.toFormat('HH:mm')}`;
    } else {
      return `${start.toLocaleString(DateTime.DATE_MED)} – ${end.toLocaleString(DateTime.DATE_MED)}`;
    }
  };

  const handleView = (eventId: string) => {
    navigate(`/events/${eventId}`);
  };

  // Open the cancellation dialog with the selected booking.
  const handleCancelClick = (booking: any) => {
    setSelectedBooking(booking);
    setCancelDialogOpen(true);
  };

  const handleConfirmCancel = async () => {
    if (!selectedBooking || !selectedBooking.event) return;
    try {
      await cancelMyEvent({
        variables: { eventId: selectedBooking.event.id },
      });
      setCancelDialogOpen(false);
      setSelectedBooking(null);
      // setCancelReason('');
    } catch (err) {
      console.error('Error cancelling event:', err);
    }
  };

  // Pagination: load more events if available.
  const handleLoadMore = () => {
    if (data?.myEventBookings?.pageInfo?.hasNextPage) {
      fetchMore({
        variables: { after: data.myEventBookings.pageInfo.endCursor },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prevResult;
          return {
            myEventBookings: {
              __typename: prevResult.myEventBookings.__typename,
              totalCount: fetchMoreResult.myEventBookings.totalCount,
              edges: [...(prevResult.myEventBookings.edges || []), ...(fetchMoreResult.myEventBookings.edges || [])],
              pageInfo: fetchMoreResult.myEventBookings.pageInfo,
            },
          };
        },
      });
    }
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5" gutterBottom>
        My Upcoming Events
      </Typography>
      {upcomingBookings.length === 0 ? (
        <Alert severity="info">You have no upcoming events.</Alert>
      ) : (
        <Grid container spacing={2}>
          {upcomingBookings.map((booking: any) => {
            const event = booking.event;
            const dateRange = formatDateRange(event.startDate.toString(), event.endDate.toString());

            return (
              <Grid item xs={12} sm={6} md={4} key={booking.id}>
                <Paper
                  sx={{
                    p: 2,
                    borderRadius: 2,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box>
                    <Typography variant="h6" sx={{ mb: 1 }}>
                      {event.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {dateRange}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                      {event.location}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                      Status: {booking.status}
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="outlined" size="small" onClick={() => handleView(event.id)}>
                      View Details
                    </Button>
                    {booking.status === EventBookingStatusEnum.Confirmed && (
                      <Button variant="contained" size="small" color="error" onClick={() => handleCancelClick(booking)}>
                        Cancel Participation
                      </Button>
                    )}
                  </Box>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      )}

      {data?.myEventBookings?.pageInfo?.hasNextPage && (
        <Box textAlign="center" mt={3}>
          <Button variant="contained" onClick={handleLoadMore}>
            Load More
          </Button>
        </Box>
      )}

      <Dialog open={cancelDialogOpen} onClose={() => setCancelDialogOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>Cancel Participation</DialogTitle>
        <DialogContent>
          <Typography variant="body1" paragraph>
            Are you sure you want to cancel your participation in "{selectedBooking?.event?.title}"?
          </Typography>
          {/* <TextField
            autoFocus
            margin="dense"
            label="Cancellation Reason"
            type="text"
            fullWidth
            variant="outlined"
            value={cancelReason}
            onChange={(e) => setCancelReason(e.target.value)}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCancelDialogOpen(false)}>Close</Button>
          <Button variant="contained" color="error" onClick={handleConfirmCancel}>
            Confirm Cancellation
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MyUpcomingEventsSection;
