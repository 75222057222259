import { Box, Divider, Typography } from '@mui/material';

const ItinerarySection: React.FC<{ itinerary: any[] | null | undefined }> = ({ itinerary }) => (
  <Box>
    <Typography variant="h6" gutterBottom>
      Itinerary
    </Typography>
    <Divider sx={{ mb: 2 }} />
    {itinerary?.map((item, index) => (
      <Box key={index} sx={{ mb: 2 }}>
        <Typography variant="subtitle1" fontWeight="bold">
          Day {index + 1}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {item.description}
        </Typography>
      </Box>
    ))}
  </Box>
);

export default ItinerarySection;
