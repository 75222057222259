import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, CircularProgress, Alert } from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';
import { EVENT_QUERY, UPDATE_EVENT_MUTATION } from '../../graphql/events';
import { CreateEventInput } from '../../generated/graphql';
import EventForm from '../../components/Events/EventForm';

const EditEventPage: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const navigate = useNavigate();

  const { data, loading, error } = useQuery(EVENT_QUERY, {
    variables: { id: eventId },
    fetchPolicy: 'network-only',
  });

  const [updateEvent, { loading: updating }] = useMutation(UPDATE_EVENT_MUTATION);

  if (loading) {
    return (
      <Container sx={{ textAlign: 'center', mt: 4 }}>
        <CircularProgress />
      </Container>
    );
  }
  if (error || !data?.event) {
    return (
      <Container sx={{ mt: 4 }}>
        <Alert severity="error">Error loading event data.</Alert>
      </Container>
    );
  }

  const event = data.event;
  // Prepopulate new address fields; if a field is missing, default to an empty string.
  const initialValues: CreateEventInput = {
    title: event.title,
    description: event.description || '',
    location: event.location,
    country: event.country || '',
    state: event.state || '',
    city: event.city || '',
    zipCode: event.zipCode || '',
    streetAddress: event.streetAddress || '',
    timezone: event.timezone || '',
    staffIds: event.staff || [],
    arrivalLocation: event.arrivalLocation || '',
    howToGetThere: event.howToGetThere || '',
    startDate: new Date(event.startDate),
    endDate: new Date(event.endDate),
    capacity: event.capacity,
    price: event.price,
    images: event.images,
    tags: event.tags || [],
  };

  const handleSubmit = async (values: CreateEventInput) => {
    const payload: CreateEventInput = { ...values };
    await updateEvent({ variables: { id: eventId, input: payload } });
    navigate(`/events/${eventId}`);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Edit Event
      </Typography>
      <EventForm initialValues={initialValues} onSubmit={handleSubmit} isSubmitting={updating} />
    </Container>
  );
};

export default EditEventPage;
