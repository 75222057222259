import { Box, Divider, Typography } from '@mui/material';

const PaymentDetails: React.FC<{ finalPrice: number; paidAmount: number }> = ({ finalPrice, paidAmount }) => {
  const remaining = finalPrice - paidAmount;
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Payment Details
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Typography variant="body2">
        Total Price: <strong>€{finalPrice}</strong>
      </Typography>
      <Typography variant="body2" sx={{ mt: 1 }}>
        Amount Paid: <strong>€{paidAmount}</strong>
      </Typography>
      <Typography variant="body2" sx={{ mt: 1 }}>
        Remaining: <strong>€{remaining}</strong>
      </Typography>
      {/* <Button variant="contained" color="primary" fullWidth sx={{ mt: 2, textTransform: 'none' }}>
        Make a Payment
      </Button> */}
    </Box>
  );
};

export default PaymentDetails;
