import { gql } from '@apollo/client';

export const REQUEST_PARTICIPATION_MUTATION = gql`
  mutation RequestParticipation($input: RequestParticipationInput!) {
    requestParticipation(input: $input) {
      id
      status
      motivation
      tripId
      userId
      createdAt
    }
  }
`;

export const TRIP_PARTICIPANTS_QUERY = gql`
  query TripParticipants($tripId: String!, $first: Int, $filter: SearchParticipantsFilterInput) {
    tripParticipants(tripId: $tripId, first: $first, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          tripId
          userId
          status
          paymentStatus
          motivation
          finalPrice
          paidAmount
          cancellationReason
          user {
            id
            firstname
            lastname
            contactAddress
            email
            phone
            imageUrl
            level
          }
          payments {
            id
            amount
            status
            createdAt
          }
          invoices {
            id
            amount
            isPaid
            paidAt
            dueDate
            createdAt
            invoiceId
            tripInvoiceType
          }
          createdAt
          updatedAt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const ACCEPT_PARTICIPATION_MUTATION = gql`
  mutation AcceptParticipation($input: AcceptParticipationInput!) {
    acceptParticipation(input: $input) {
      id
      status
      userId
      updatedAt
    }
  }
`;

export const REJECT_PARTICIPATION_MUTATION = gql`
  mutation RejectParticipation($input: RejectParticipationInput!) {
    rejectParticipation(input: $input) {
      id
      status
      userId
      updatedAt
    }
  }
`;

export const CANCEL_ACTIVE_PARTICIPATION_MUTATION = gql`
  mutation CancelActiveParticipation($input: CancelActiveParticipationInput!) {
    cancelActiveParticipation(input: $input) {
      id
      status
      isRemoved
      removedAt
      userId
      updatedAt
    }
  }
`;

export const DELETE_PARTICIPATION_MUTATION = gql`
  mutation DeleteParticipation($tripId: String!, $participantId: String!) {
    deleteParticipation(tripId: $tripId, participantId: $participantId) {
      id
    }
  }
`;

export const ADD_PARTICIPANT_PAYMENT_MUTATION = gql`
  mutation AddParticipantPayment($input: AddPaymentInput!) {
    addParticipantPayment(input: $input) {
      id
      amount
      status
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_PAYMENT_STATUS_MUTATION = gql`
  mutation UpdateParticipantPaymentStatus($input: UpdatePaymentStatusInput!) {
    updateParticipantPaymentStatus(input: $input) {
      id
      amount
      status
      createdAt
    }
  }
`;

export const CREATE_TRIP_PARTICIPANT_MUTATION = gql`
  mutation CreateTripParticipant($input: CreateTripParticipantInput!) {
    createTripParticipant(input: $input) {
      id
      userId
      tripId
      status
      finalPrice
      user {
        id
        firstname
        lastname
        email
      }
    }
  }
`;

export const MY_TRIP_PARTICIPATIONS_QUERY = gql`
  query MyTripParticipations {
    myTripParticipations {
      id
      status
      paymentStatus
      hasTripReview
      hasGuideReview
      trip {
        id
        status
        title
        startDate
        endDate
        continent
        images
        country
        price
      }
    }
  }
`;

export const CANCEL_MY_PARTICIPATION_MUTATION = gql`
  mutation CancelMyParticipation($input: CancelMyParticipationInput!) {
    cancelMyParticipation(input: $input) {
      id
      status
      cancellationReason
      isRemoved
      removedAt
      updatedAt
    }
  }
`;

export const PUBLIC_TRIP_PARTICIPANTS_QUERY = gql`
  query PublicTripParticipants($tripId: String!) {
    publicTripParticipants(tripId: $tripId) {
      id
      firstname
      lastname
      imageUrl
      exp
      level
    }
  }
`;

export const MY_TRIP_PAYMENTS = gql`
  query MyTripPayments($tripId: String!) {
    myTripPayments(tripId: $tripId) {
      id
      createdAt
      updatedAt
      amount
      status
    }
  }
`;

export const MY_PARTICIPATION = gql`
  query MyParticipation($tripId: String!) {
    myParticipation(tripId: $tripId) {
      id
      tripId
      userId
      status
      paymentStatus
      motivation
      finalPrice
      paidAmount
      cancellationReason
      user {
        id
        firstname
        lastname
        contactAddress
        email
        phone
        imageUrl
        level
      }
      payments {
        id
        amount
        status
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
