import { Box, Divider, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { MeetingDetail } from '../../generated/graphql';

const MeetingDetails: React.FC<{ meetingDetail: MeetingDetail | null | undefined }> = ({ meetingDetail }) => {
  // If meetingDetail is not defined, show a placeholder.
  if (!meetingDetail) {
    return (
      <Box>
        <Typography variant="h6" gutterBottom>
          Meeting Details
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Typography variant="body2" color="textSecondary">
          No meeting details available.
        </Typography>
      </Box>
    );
  }
  const meetingTime = DateTime.fromJSDate(new Date(meetingDetail.meetingTime)).toFormat('dd LLL yyyy, HH:mm');
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Meeting Details
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Typography variant="body2" color="textSecondary">
        Meeting Time: {meetingTime}
      </Typography>
      <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
        Location: {meetingDetail.location}
      </Typography>
      <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
        Instructions: {meetingDetail.description}
      </Typography>
    </Box>
  );
};

export default MeetingDetails;
