import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import {
  Box,
  CircularProgress,
  Alert,
  Typography,
  Tabs,
  Tab,
  Paper,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Switch,
  Card,
  CardContent,
} from '@mui/material';
import {
  TRIP_PARTICIPANTS_QUERY,
  ACCEPT_PARTICIPATION_MUTATION,
  REJECT_PARTICIPATION_MUTATION,
  CANCEL_ACTIVE_PARTICIPATION_MUTATION,
  DELETE_PARTICIPATION_MUTATION,
} from '../../graphql/trip-participants';
import {
  TripParticipant,
  ParticipantStatusEnum,
  Role,
  TripParticipantsQuery,
  TripParticipantsQueryVariables,
  AcceptParticipationMutation,
  AcceptParticipationMutationVariables,
  RejectParticipationMutation,
  RejectParticipationMutationVariables,
  CancelActiveParticipationMutation,
  CancelActiveParticipationMutationVariables,
  DeleteParticipationMutation,
  DeleteParticipationMutationVariables,
  Trip,
  TripQuery,
  TripQueryVariables,
  User,
} from '../../generated/graphql';
import { useAuth } from '../../contexts/AuthContext';
import { isAdminGuard } from '../../utils/isAdminGuard';
import { TRIP_QUERY } from '../../graphql/trips';
import Expenses from '../../components/Organizer/Expenses/Expenses';
import UserSearch from '../../components/Participant/UserSearch/UserSearch';
import { DateTime } from 'luxon';
import { UPDATE_TRIP_IS_JOINABLE_MUTATION } from '../../graphql/trips';
import ParticipantTable from '../../components/Participant/ParticipantTable';

const statusesInOrder: ParticipantStatusEnum[] = [
  ParticipantStatusEnum.Approved,
  ParticipantStatusEnum.Pending,
  ParticipantStatusEnum.Rejected,
  ParticipantStatusEnum.Cancelled,
  ParticipantStatusEnum.CancelledByParticipant,
];

const OrganizerTripManagementPage: React.FC = () => {
  const { user } = useAuth();
  const { tripId } = useParams<{ tripId: string }>();

  const {
    data: tripData,
    loading: tripLoading,
    error: tripError,
  } = useQuery<TripQuery, TripQueryVariables>(TRIP_QUERY, {
    variables: { id: tripId! },
    skip: !tripId,
  });

  const trip = tripData?.trip;
  const [activeTab, setActiveTab] = useState<number>(0);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [newJoinableStatus, setNewJoinableStatus] = useState<boolean | null>(null);
  const [totalExpenses, setTotalExpenses] = useState(0);

  const { data, loading, error, refetch } = useQuery<TripParticipantsQuery, TripParticipantsQueryVariables>(
    TRIP_PARTICIPANTS_QUERY,
    {
      variables: { tripId: tripId!, first: 100 },
      fetchPolicy: 'network-only',
      skip: !tripId,
    }
  );

  const [acceptParticipation] = useMutation<AcceptParticipationMutation, AcceptParticipationMutationVariables>(
    ACCEPT_PARTICIPATION_MUTATION,
    {
      refetchQueries: [{ query: TRIP_PARTICIPANTS_QUERY, variables: { tripId, first: 100 } }],
    }
  );
  const [rejectParticipation] = useMutation<RejectParticipationMutation, RejectParticipationMutationVariables>(
    REJECT_PARTICIPATION_MUTATION,
    {
      refetchQueries: [{ query: TRIP_PARTICIPANTS_QUERY, variables: { tripId, first: 100 } }],
    }
  );
  const [cancelActiveParticipation] = useMutation<
    CancelActiveParticipationMutation,
    CancelActiveParticipationMutationVariables
  >(CANCEL_ACTIVE_PARTICIPATION_MUTATION, {
    refetchQueries: [{ query: TRIP_PARTICIPANTS_QUERY, variables: { tripId, first: 100 } }],
  });
  const [deleteParticipation] = useMutation<DeleteParticipationMutation, DeleteParticipationMutationVariables>(
    DELETE_PARTICIPATION_MUTATION,
    {
      refetchQueries: [{ query: TRIP_PARTICIPANTS_QUERY, variables: { tripId, first: 100 } }],
    }
  );

  const [updateTripJoinableStatus] = useMutation(UPDATE_TRIP_IS_JOINABLE_MUTATION, {
    onCompleted: () => {
      refetch();
    },
  });

  if (!tripId) return <Alert severity="error">Trip ID is required.</Alert>;
  if (!user || (!isAdminGuard(user) && !user.roles.includes(Role.Organizer))) {
    return <Alert severity="error">Not authorized.</Alert>;
  }
  if (tripLoading || loading) {
    return (
      <Box textAlign="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }
  if (tripError || error) {
    return <Alert severity="error">{tripError?.message || error?.message}</Alert>;
  }
  if (!trip) {
    return <Alert severity="error">Trip not found.</Alert>;
  }

  const participants: TripParticipant[] =
    data?.tripParticipants?.edges?.map((edge) => edge.node as TripParticipant) || [];

  const participantsByStatus = (status: ParticipantStatusEnum) => participants.filter((p) => p.status === status);

  const handleAccept = async (userId: string, finalPrice?: number) => {
    try {
      await acceptParticipation({ variables: { input: { tripId, userId, finalPrice } } });
      refetch();
    } catch (err) {
      console.error(err);
    }
  };

  const handleReject = async (userId: string) => {
    try {
      await rejectParticipation({ variables: { input: { tripId, userId } } });
      refetch();
    } catch (err) {
      console.error(err);
    }
  };

  const handleRemove = async (userId: string) => {
    try {
      await cancelActiveParticipation({ variables: { input: { tripId, userId } } });
      refetch();
    } catch (err) {
      console.error(err);
    }
  };

  const handleDelete = async (participantId: string) => {
    try {
      await deleteParticipation({ variables: { tripId, participantId } });
      refetch();
    } catch (err) {
      console.error(err);
    }
  };

  const handleTabChange = (e: React.SyntheticEvent, newIndex: number) => {
    setActiveTab(newIndex);
  };

  const excludedUserIds = participants.map((p) => p.userId);

  const handleJoinableToggle = (newStatus: boolean) => {
    setNewJoinableStatus(newStatus);
    setConfirmDialogOpen(true);
  };

  const handleConfirmStatusChange = async () => {
    if (newJoinableStatus === null) return;
    try {
      await updateTripJoinableStatus({
        variables: {
          tripId,
          isJoinable: newJoinableStatus,
        },
      });
      setConfirmDialogOpen(false);
    } catch (err) {
      console.error(err);
    }
  };

  const totalIncome = (trip.numberOfPeople - (trip.guides?.length || 0)) * trip.price;

  const handleExpensesTotalChange = (sum: number) => {
    setTotalExpenses(sum);
  };

  const netProfit = totalIncome - totalExpenses;

  return (
    <Box sx={{ p: 0 }}>
      <Box
        sx={{
          position: 'relative',
          height: '200px',
          width: '100%',
          mb: 3,
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1,
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundImage: `url(${trip.images?.[0] || '/default-trip-cover.jpg'})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Container
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            zIndex: 2,
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="h3"
            sx={{
              color: 'white',
              fontWeight: 'bold',
              textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
              textAlign: 'center',
            }}
          >
            {trip.title}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: 'white',
              textAlign: 'center',
              textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
              mt: 1,
            }}
          >
            {DateTime.fromISO(trip.startDate).toLocaleString(DateTime.DATE_MED)} -{' '}
            {DateTime.fromISO(trip.endDate).toLocaleString(DateTime.DATE_MED)}
          </Typography>
        </Container>
      </Box>

      <Box sx={{ px: 2 }}>
        {isAdminGuard(user) && (
          <Card variant="outlined" sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="h6">Admin Overview</Typography>
              <Typography variant="body1">Total Income : €{totalIncome.toFixed(2)}</Typography>
              <Typography variant="body1">Total Expenses: €{totalExpenses.toFixed(2)}</Typography>
              <Typography variant="body1">
                Net: <span style={{ color: netProfit >= 0 ? 'green' : 'red' }}>€{netProfit.toFixed(2)}</span>
              </Typography>
            </CardContent>
          </Card>
        )}

        <Paper sx={{ mb: 2 }}>
          <Tabs value={activeTab} onChange={handleTabChange} centered>
            <Tab label="Participants" />
            <Tab label="Add Participants" />
            <Tab label="Expenses" />
          </Tabs>
        </Paper>

        {activeTab === 0 && (
          <>
            <Paper sx={{ p: 2, mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={trip.isJoinable}
                    onChange={(e) => handleJoinableToggle(e.target.checked)}
                    color="primary"
                  />
                }
                label={trip.isJoinable ? 'Accepting Join Requests' : 'Join Requests Disabled'}
              />
            </Paper>

            {statusesInOrder.map((status) => (
              <ParticipantTable
                key={status}
                status={status}
                participants={participantsByStatus(status)}
                onAccept={handleAccept}
                onReject={handleReject}
                onCancel={handleRemove}
                onDelete={handleDelete}
                trip={trip as Trip}
                user={user as User}
              />
            ))}

            <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
              <DialogTitle>Confirm Status Change</DialogTitle>
              <DialogContent>
                Are you sure you want to {newJoinableStatus ? 'enable' : 'disable'} join requests for this trip?
                {!newJoinableStatus && (
                  <Typography color="warning.main" sx={{ mt: 1 }}>
                    This will prevent new participants from requesting to join the trip.
                  </Typography>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
                <Button onClick={handleConfirmStatusChange} color="primary" variant="contained">
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}

        {activeTab === 1 && (
          <UserSearch
            tripId={tripId!}
            excludedUserIds={excludedUserIds}
            onParticipantAdded={refetch}
            trip={trip as Trip}
          />
        )}

        {activeTab === 2 && <Expenses tripId={tripId!} onTotalExpenseChange={handleExpensesTotalChange} />}
      </Box>
    </Box>
  );
};

export default OrganizerTripManagementPage;
