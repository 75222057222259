import React, { useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Paper,
  Avatar,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { ParticipantStatusEnum, Trip, TripParticipant, User, TripInvoice, Role } from '../../generated/graphql';
import ParticipantDetailModal from './ParticipantDetailModal/ParticipantDetailModal';

interface ParticipantTableProps {
  status: ParticipantStatusEnum;
  participants: TripParticipant[];
  onAccept: (userId: string, finalPrice?: number) => void;
  onReject: (userId: string) => void;
  onDelete: (participantId: string) => void;
  onCancel: (userId: string) => void;
  trip: Trip;
  user: User;
}

const ParticipantTable: React.FC<ParticipantTableProps> = ({
  status,
  participants,
  onAccept,
  onReject,
  onDelete,
  onCancel,
  trip,
  user,
}) => {
  const [detailOpen, setDetailOpen] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState<TripParticipant | null>(null);

  const handleOpenDetail = (p: TripParticipant) => {
    setSelectedParticipant(p);
    setDetailOpen(true);
  };

  const handleCloseDetail = () => {
    setDetailOpen(false);
    setSelectedParticipant(null);
  };

  const getStatusColor = (status: ParticipantStatusEnum) => {
    switch (status) {
      case ParticipantStatusEnum.CancelledByParticipant:
        return '#ed6c02';
      case ParticipantStatusEnum.Cancelled:
        return '#d32f2f';
      case ParticipantStatusEnum.Pending:
        return '#ed6c02';
      default:
        return 'inherit';
    }
  };

  const getInvoiceSummary = (invoices?: TripInvoice[]) => {
    if (!invoices || invoices.length === 0) return '-';
    const totalPaid = invoices.reduce((acc, inv) => (inv.isPaid ? acc + inv.amount : acc), 0);
    const totalAmount = invoices.reduce((acc, inv) => acc + inv.amount, 0);
    return `€${totalPaid.toFixed(2)} / €${totalAmount.toFixed(2)}`;
  };

  if (!participants?.length) {
    return <Box sx={{ p: 2, mb: 2 }} />;
  }

  return (
    <>
      <Paper sx={{ p: 2, mb: 4 }} elevation={2}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {status} Participants ({participants.length})
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Final Price</TableCell>
              <TableCell>Invoices (Paid / Total)</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {participants.map((p: TripParticipant) => {
              const displayName = `${p.user?.firstname || ''} ${p.user?.lastname || ''}`;
              return (
                <TableRow key={p.id}>
                  <TableCell>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Avatar
                        src={p.user?.imageUrl || '/images/default_avatar.png'}
                        alt={displayName}
                        sx={{ width: 32, height: 32 }}
                      />
                      <Typography variant="body2">{displayName}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body2"
                      sx={{
                        color: getStatusColor(p.status),
                        fontWeight: p.status === ParticipantStatusEnum.CancelledByParticipant ? 'bold' : 'inherit',
                      }}
                    >
                      {p.status === ParticipantStatusEnum.CancelledByParticipant ? 'Cancellation Requested' : p.status}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">€{p.finalPrice || '-'}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{getInvoiceSummary(p.invoices as TripInvoice[])}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Manage Participant">
                      <IconButton onClick={() => handleOpenDetail(p)} size="small">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>

      {selectedParticipant && (
        <ParticipantDetailModal
          open={detailOpen}
          onClose={handleCloseDetail}
          participant={selectedParticipant}
          trip={trip}
          onAccept={onAccept}
          onReject={onReject}
          onCancel={onCancel}
          onDelete={onDelete}
          userIsAdmin={user.roles.includes(Role.Admin)}
        />
      )}
    </>
  );
};

export default ParticipantTable;
