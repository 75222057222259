import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box, CircularProgress, Alert, Paper, Button, Grid, Divider } from '@mui/material';
import { useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import { GUIDE_REVIEWS_QUERY } from '../../graphql/reviews';
import {
  GuideReviewsQuery,
  GuideReviewsQueryVariables,
  GuideReviewOrder,
  GuideReviewOrderField,
} from '../../generated/graphql';

const GuideReviewsPage: React.FC = () => {
  const { guideId } = useParams<{ guideId: string }>();

  const { data, loading, error, fetchMore } = useQuery<GuideReviewsQuery, GuideReviewsQueryVariables>(
    GUIDE_REVIEWS_QUERY,
    {
      variables: {
        guideId: guideId!,
        first: 10,
        orderBy: { field: GuideReviewOrderField.CreatedAt, direction: 'desc' } as GuideReviewOrder,
      },
      fetchPolicy: 'network-only',
    }
  );

  if (loading) {
    return (
      <Box textAlign="center" mt={6}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return <Alert severity="error">Error loading reviews: {error.message}</Alert>;
  }

  // The query returns a single connection.
  const connection = data?.guideReviews;
  const allEdges = connection?.edges || [];
  const pageInfo = connection?.pageInfo;

  const handleLoadMore = () => {
    if (pageInfo?.hasNextPage) {
      fetchMore({
        variables: { after: pageInfo.endCursor },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            guideReviews: {
              __typename: prev.guideReviews.__typename,
              totalCount: fetchMoreResult.guideReviews.totalCount,
              edges: [...(prev.guideReviews.edges || []), ...(fetchMoreResult.guideReviews.edges || [])],
              pageInfo: fetchMoreResult.guideReviews.pageInfo,
            },
          };
        },
      });
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 6, mb: 6 }}>
      <Typography variant="h4" sx={{ fontWeight: 700, mb: 4 }}>
        Guide Reviews
      </Typography>
      {allEdges.length === 0 ? (
        <Alert severity="info">No reviews found for this guide.</Alert>
      ) : (
        <Grid container spacing={3}>
          {allEdges.map(({ cursor, node }) => {
            const reviewDate = DateTime.fromISO(node.createdAt).toLocaleString(DateTime.DATE_MED);
            const tripStart = DateTime.fromISO(node.trip.startDate.toString()).toLocaleString(DateTime.DATE_MED);
            const tripEnd = DateTime.fromISO(node.trip.endDate.toString()).toLocaleString(DateTime.DATE_MED);

            return (
              <Grid item xs={12} key={cursor}>
                <Paper
                  elevation={2}
                  sx={{
                    p: 3,
                    borderRadius: 3,
                    transition: 'transform 0.2s ease-in-out',
                    '&:hover': { transform: 'scale(1.02)' },
                  }}
                >
                  {/* Reviewer Header */}
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    {node.user.firstname} {node.user.lastname} – {node.overallRating}/5
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                    Reviewed on {reviewDate}
                  </Typography>
                  <Divider sx={{ my: 1 }} />

                  {/* Ratings Details */}
                  <Box sx={{ mb: 1 }}>
                    {node.ratingCommunication !== null && (
                      <Typography variant="body2">
                        <strong>Communication:</strong> {node.ratingCommunication}/5
                      </Typography>
                    )}
                    {node.ratingApproach !== null && (
                      <Typography variant="body2">
                        <strong>Approach:</strong> {node.ratingApproach}/5
                      </Typography>
                    )}
                    {node.timingAccuracy !== null && (
                      <Typography variant="body2">
                        <strong>Timing Accuracy:</strong> {node.timingAccuracy}/5
                      </Typography>
                    )}
                    {node.drivingSkills !== null && (
                      <Typography variant="body2">
                        <strong>Driving Skills:</strong> {node.drivingSkills}/5
                      </Typography>
                    )}
                    {node.funRating !== null && (
                      <Typography variant="body2">
                        <strong>Fun Rating:</strong> {node.funRating}/5
                      </Typography>
                    )}
                    {node.organizationRating !== null && (
                      <Typography variant="body2">
                        <strong>Organization:</strong> {node.organizationRating}/5
                      </Typography>
                    )}
                    {node.extraInformationPositive && (
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        <strong>Pros:</strong> {node.extraInformationPositive}
                      </Typography>
                    )}
                    {node.extraInformationNegative && (
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        <strong>Cons:</strong> {node.extraInformationNegative}
                      </Typography>
                    )}
                  </Box>

                  {/* Trip Info */}
                  <Divider sx={{ my: 1 }} />
                  <Typography variant="body2" color="text.secondary">
                    For trip: <strong>{node.trip.title}</strong> ({tripStart} – {tripEnd})
                  </Typography>
                  <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1 }}>
                    Updated at {DateTime.fromISO(node.updatedAt).toLocaleString(DateTime.DATETIME_MED)}
                  </Typography>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      )}
      {pageInfo?.hasNextPage && (
        <Box textAlign="center" mt={4}>
          <Button variant="contained" onClick={handleLoadMore} sx={{ borderRadius: 3 }}>
            Load More Reviews
          </Button>
        </Box>
      )}
    </Container>
  );
};

export default GuideReviewsPage;
