import React from 'react';
import { useQuery } from '@apollo/client';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { Box, CircularProgress, Alert, Typography, styled } from '@mui/material';
import { Role, TripReviewStatsForUserQuery, TripReviewStatsForUserQueryVariables } from '../../../generated/graphql';
import { TRIP_REVIEW_STATS_FOR_USER_QUERY } from '../../../graphql/trip-reviews';

// 1) Container for the horizontally scrollable row
const ScrollRow = styled('div')(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  gap: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  // minimal scrollbar style
  '&::-webkit-scrollbar': {
    height: 6,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#ccc',
    borderRadius: 4,
  },
}));

// 2) The “card” root, using a <div> so we have more custom control
const StatsCard = styled('div')(({ theme }) => ({
  position: 'relative',
  minWidth: 280,
  height: 180,
  flexShrink: 0,
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 3px 8px rgba(0,0,0,0.15)',
  overflow: 'hidden',
  backgroundColor: '#f5f5f5', // fallback in case no image
}));

// 3) The background image
const BackgroundImage = styled('div')(({ theme }) => ({
  position: 'absolute',
  inset: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  // A subtle darkening to make text more readable
  filter: 'brightness(0.65)',
}));

// 4) A content overlay for text, so it sits above the background image
const ContentOverlay = styled('div')(({ theme }) => ({
  position: 'relative',
  zIndex: 2,
  padding: theme.spacing(2),
  color: '#fff', // text white for contrast
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  height: '100%',
  // optional backdrop or gradient
  background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.45) 100%)',
}));

interface OrganizerStatsSectionProps {
  organizerId: string;
}

const OrganizerStatsSection: React.FC<OrganizerStatsSectionProps> = ({ organizerId }) => {
  const { user } = useAuth();

  const { data, loading, error } = useQuery<TripReviewStatsForUserQuery, TripReviewStatsForUserQueryVariables>(
    TRIP_REVIEW_STATS_FOR_USER_QUERY,
    {
      variables: {
        organizerId,
        first: 10,
      },
      fetchPolicy: 'network-only',
    }
  );

  if (loading) {
    return (
      <Box textAlign="center" mt={2}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return <Alert severity="error">Error loading stats: {error.message}</Alert>;
  }

  const statsConnection = data?.tripReviewStatsForUser;
  // Return null if no stats - this means the user is not an organizer
  if (!statsConnection || !statsConnection.edges?.length) {
    return null;
  }

  const canClickTitle =
    user && (user.roles.includes(Role.Admin) || user.roles.includes(Role.Organizer) || user.roles.includes(Role.Guide));

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
        Trip Review Stats
      </Typography>

      <ScrollRow>
        {statsConnection.edges.map((edge) => {
          const statsNode = edge.node;
          if (!statsNode) return null;

          const { trip } = statsNode;
          const firstImage = trip?.images?.[0] || '/images/goyamboka-beach.jpeg';

          return (
            <StatsCard key={edge.cursor}>
              {/* background image layer */}
              <BackgroundImage style={{ backgroundImage: `url('${firstImage}')` }} />
              {/* text overlay */}
              <ContentOverlay>
                {canClickTitle ? (
                  <Typography
                    variant="subtitle1"
                    component={RouterLink}
                    to={`/trip/${trip?.id}/reviews`}
                    sx={{
                      fontWeight: 'bold',
                      textDecoration: 'none',
                      color: 'inherit',
                    }}
                  >
                    {trip?.title || 'Untitled Trip'}
                  </Typography>
                ) : (
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    {trip?.title || 'Untitled Trip'}
                  </Typography>
                )}

                <Typography variant="body2" sx={{ mt: 1 }}>
                  Overall: {statsNode.averageOverall?.toFixed(1) || 'N/A'}/5
                </Typography>
                {/* TODO: what fields?? */}
                {/* <Typography variant="body2">
                  Accommodation: {statsNode.averageAccommodation?.toFixed(1) || 'N/A'}/5
                </Typography>
                <Typography variant="body2">
                  Surf Lessons: {statsNode.averageSurfLessons?.toFixed(1) || 'N/A'}/5
                </Typography> */}
                <Typography variant="body2" sx={{ mt: 1, fontStyle: 'italic' }}>
                  {statsNode.reviewCount} total reviews
                </Typography>
              </ContentOverlay>
            </StatsCard>
          );
        })}
      </ScrollRow>
    </Box>
  );
};

export default OrganizerStatsSection;
