import React, { useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { DateTime } from 'luxon';
import {
  Grid,
  Button,
  Paper,
  Typography,
  Snackbar,
  Alert,
  Box,
  Chip,
  Autocomplete,
  TextField,
  CircularProgress,
} from '@mui/material';
import { CreateEventInput } from '../../generated/graphql';
import FormikDateTimePicker from '../common/FormikDateTimePicker/FormikDateTimePicker';
import ImageUploadEvent from './ImageUploadEvent';
import countryList from 'country-list';
import StaffSelect from './StaffSelect';
import RichTextEditor from '../common/RichTextEditor/RichTextEditor';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
  location: Yup.string().required('Location is required'),
  country: Yup.string().required('Country is required'),
  city: Yup.string().required('City is required'),
  zipCode: Yup.string().required('Zip Code is required'),
  streetAddress: Yup.string().required('Street Name is required'),
  timezone: Yup.string(),
  arrivalLocation: Yup.string(),
  howToGetThere: Yup.string(),
  startDate: Yup.date().required('Start date is required'),
  endDate: Yup.date()
    .required('End date is required')
    .min(Yup.ref('startDate'), 'End date cannot be before start date'),
  capacity: Yup.number().min(1, 'At least 1 participant is required').required('Capacity is required'),
  price: Yup.number().min(0, 'Price cannot be negative').required('Price is required'),
  images: Yup.array().of(Yup.string()).min(1, 'At least one image is required'),
  tags: Yup.array().of(Yup.string()),
});

interface EventFormProps {
  initialValues: CreateEventInput;
  onSubmit: (values: CreateEventInput, actions: FormikHelpers<CreateEventInput>) => Promise<void>;
  isSubmitting: boolean;
}

const EventForm: React.FC<EventFormProps> = ({ initialValues, onSubmit, isSubmitting }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const [newTag, setNewTag] = useState('');
  const [imageUrls, setImageUrls] = useState<string[]>(initialValues.images || []);
  const countryOptions = countryList.getData();
  // Local state for staff selection – we store selected staff as an array of User objects.
  const [selectedStaff, setSelectedStaff] = useState<any[]>(initialValues.staffIds || []);

  const handleSnackbarClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  // Helper: Given latitude and longitude, fetch timezone (using TimeZoneDB)
  const fetchTimezoneFromCoords = async (lat: number, lon: number): Promise<string> => {
    const apiKey = process.env.REACT_APP_TIMEZONEDB_API_KEY;
    try {
      const response = await fetch(
        `https://api.timezonedb.com/v2.1/get-time-zone?key=${apiKey}&format=json&by=position&lat=${lat}&lng=${lon}`
      );
      const data = await response.json();
      if (data && data.status === 'OK') {
        return data.zoneName;
      }
    } catch (e) {
      console.error(e);
    }
    return 'Europe/Bratislava';
  };

  // Handle detecting address via geolocation (stubbed)
  const handleDetectAddress = async (setFieldValue: (field: string, value: any) => void) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          // Replace with a proper reverse-geocoding API in production.
          const detectedCountry = 'SK';
          const detectedCity = 'Bratislava';
          const detectedZipCode = '81101';
          const detectedStreetAddress = 'Example Street 1';
          setFieldValue('country', detectedCountry);
          setFieldValue('city', detectedCity);
          setFieldValue('zipCode', detectedZipCode);
          setFieldValue('streetAddress', detectedStreetAddress);
          const tz = await fetchTimezoneFromCoords(latitude, longitude);
          setFieldValue('timezone', tz);
        },
        (error) => {
          console.error(error);
          setSnackbarMsg('Unable to retrieve address');
          setSnackbarOpen(true);
        }
      );
    } else {
      setSnackbarMsg('Geolocation is not supported by your browser.');
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <Formik<CreateEventInput>
        initialValues={{ ...initialValues, images: imageUrls }}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          try {
            // Transform staff selection: map the selected staff objects to an array of user IDs.
            const payload: CreateEventInput = {
              ...values,
              staffIds: selectedStaff.map((staff) => staff.id),
              images: imageUrls,
            };
            await onSubmit(payload, actions);
          } catch (error: any) {
            setSnackbarMsg(error.message || 'Submission failed');
            setSnackbarOpen(true);
          }
          actions.setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          isSubmitting: formSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Paper sx={{ p: 3, mb: 3 }}>
              <Typography variant="h5" gutterBottom>
                Event Details
              </Typography>
              <Grid container spacing={2}>
                {/* Title */}
                <Grid item xs={12}>
                  <TextField
                    label="Title"
                    name="title"
                    fullWidth
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.title && Boolean(errors.title)}
                    helperText={touched.title && errors.title}
                  />
                </Grid>
                {/* Description using Rich Text Editor */}
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    Description
                  </Typography>
                  <RichTextEditor
                    content={values.description || ''}
                    onChange={(value: string) => setFieldValue('description', value)}
                  />
                </Grid>
                {/* General Location */}
                <Grid item xs={12}>
                  <TextField
                    label="General Location"
                    name="location"
                    fullWidth
                    value={values.location}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.location && Boolean(errors.location)}
                    helperText={touched.location && errors.location}
                  />
                </Grid>
                {/* Country */}
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    fullWidth
                    id="country-autocomplete"
                    options={countryOptions}
                    getOptionLabel={(option) => option.name}
                    value={countryOptions.find((c) => c.code === values.country) || null}
                    onChange={(_event, newValue) => {
                      setFieldValue('country', newValue ? newValue.code : '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        name="country"
                        error={touched.country && Boolean(errors.country)}
                        helperText={touched.country && errors.country}
                      />
                    )}
                  />
                </Grid>
                {/* City */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="City"
                    name="city"
                    fullWidth
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city}
                  />
                </Grid>
                {/* Zip Code */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Zip Code"
                    name="zipCode"
                    fullWidth
                    value={values.zipCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.zipCode && Boolean(errors.zipCode)}
                    helperText={touched.zipCode && errors.zipCode}
                  />
                </Grid>
                {/* Street Name */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Street Name"
                    name="streetAddress"
                    fullWidth
                    value={values.streetAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.streetAddress && Boolean(errors.streetAddress)}
                    helperText={touched.streetAddress && errors.streetAddress}
                  />
                </Grid>
                {/* Detect Address Button */}
                <Grid item xs={12}>
                  <Button variant="outlined" fullWidth onClick={() => handleDetectAddress(setFieldValue)}>
                    Auto-Fill Address & Timezone
                  </Button>
                </Grid>
                {/* Arrival Location */}
                <Grid item xs={12}>
                  <TextField
                    label="Arrival Location (in case of meeting point elsewhere)"
                    name="arrivalLocation"
                    fullWidth
                    value={values.arrivalLocation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.arrivalLocation && Boolean(errors.arrivalLocation)}
                    helperText={touched.arrivalLocation && errors.arrivalLocation}
                  />
                </Grid>
                {/* How To Get There */}
                <Grid item xs={12}>
                  <TextField
                    label="How to Get There"
                    name="howToGetThere"
                    fullWidth
                    multiline
                    rows={3}
                    value={values.howToGetThere}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.howToGetThere && Boolean(errors.howToGetThere)}
                    helperText={touched.howToGetThere && errors.howToGetThere}
                  />
                </Grid>
                {/* Start Date & Time */}
                <Grid item xs={12} sm={6}>
                  <FormikDateTimePicker name="startDate" label="Start Date and Time" />
                </Grid>
                {/* End Date & Time */}
                <Grid item xs={12} sm={6}>
                  <FormikDateTimePicker
                    name="endDate"
                    label="End Date and Time"
                    minDateTime={values.startDate ? DateTime.fromJSDate(new Date(values.startDate)) : undefined}
                  />
                </Grid>
                {/* Capacity */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Capacity"
                    name="capacity"
                    type="number"
                    fullWidth
                    value={values.capacity}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.capacity && Boolean(errors.capacity)}
                    helperText={touched.capacity && errors.capacity}
                  />
                </Grid>
                {/* Price */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Price (€)"
                    name="price"
                    type="number"
                    fullWidth
                    value={values.price}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.price && Boolean(errors.price)}
                    helperText={touched.price && errors.price}
                    InputProps={{ inputProps: { min: 0, step: '0.01' } }}
                  />
                </Grid>
                {/* Staff Selector */}
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    Staff (Coaches/Guides)
                  </Typography>
                  <StaffSelect selectedStaff={selectedStaff} onChange={setSelectedStaff} />
                </Grid>
                {/* Tags Section */}
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    Tags
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 1 }}>
                    {values.tags &&
                      values.tags.map((tag, idx) => (
                        <Chip
                          key={idx}
                          label={tag}
                          onDelete={() => {
                            const updatedTags = values.tags?.filter((_, index) => index !== idx);
                            setFieldValue('tags', updatedTags);
                          }}
                          sx={{ margin: '2px' }}
                        />
                      ))}
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <TextField label="Add Tag" value={newTag} onChange={(e) => setNewTag(e.target.value)} fullWidth />
                    <Button
                      variant="contained"
                      onClick={() => {
                        if (newTag.trim() !== '') {
                          const updatedTags = values.tags ? [...values.tags, newTag.trim()] : [newTag.trim()];
                          setFieldValue('tags', updatedTags);
                          setNewTag('');
                        }
                      }}
                    >
                      Add
                    </Button>
                  </Box>
                </Grid>
                {/* Images – fallback comma-separated URLs */}
                <Grid item xs={12}>
                  <TextField
                    label="Images (comma-separated URLs)"
                    name="images"
                    fullWidth
                    value={values.images?.join(', ')}
                    onChange={(e) => {
                      // Split by comma, trim each URL, and filter out empty strings.
                      const arr = e.target.value
                        .split(',')
                        .map((s) => s.trim())
                        .filter((s) => s.length > 0);
                      setFieldValue('images', arr);
                      setImageUrls(arr);
                    }}
                    onBlur={handleBlur}
                    error={touched.images && Boolean(errors.images)}
                    helperText={touched.images && errors.images}
                  />
                </Grid>
                {/* Custom Image Upload Component */}
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    Or Upload Images
                  </Typography>
                  <ImageUploadEvent
                    onUploadSuccess={(url: string) => {
                      const updated = [...imageUrls, url];
                      setFieldValue('images', updated);
                      setImageUrls(updated);
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
            <Box sx={{ textAlign: 'center', mt: 3 }}>
              <Button variant="contained" color="primary" type="submit" disabled={isSubmitting || formSubmitting}>
                {isSubmitting || formSubmitting ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Submit'}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EventForm;
