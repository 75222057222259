import { Box, Divider, Typography } from '@mui/material';

const MessagesSection: React.FC = () => {
  // Dummy messages data – replace with real chat/messages data when available.
  const dummyMessages = [
    { id: 'msg1', sender: 'Organizer', content: 'Welcome aboard! Please arrive 15 minutes early.' },
    { id: 'msg2', sender: 'Guide', content: 'I will be at the meeting point at 8:45 AM.' },
    { id: 'msg3', sender: 'Organizer', content: 'Let me know if you have any questions.' },
  ];

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Messages
      </Typography>
      <Divider sx={{ mb: 2 }} />
      {dummyMessages.map((msg) => (
        <Box key={msg.id} sx={{ mb: 1, p: 1, border: '1px solid #eee', borderRadius: 1 }}>
          <Typography variant="subtitle2" color="primary">
            {msg.sender}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {msg.content}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default MessagesSection;
