// CreateEventPage.tsx
import React, { useMemo } from 'react';
import { Container, Typography, Box, CircularProgress, Alert } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { CREATE_EVENT_MUTATION, EVENT_QUERY } from '../../graphql/events';
import { CreateEventInput, Event } from '../../generated/graphql';
import EventForm from '../../components/Events/EventForm';

interface LocationState {
  copiedEventId?: string;
}

const CreateEventPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation() as { state?: LocationState };
  const copiedEventId = location.state?.copiedEventId;

  // Query for the original event if we are copying an event.
  const {
    data: eventData,
    loading: eventLoading,
    error: eventError,
  } = useQuery<{ event: Event }>(EVENT_QUERY, {
    variables: { id: copiedEventId },
    skip: !copiedEventId,
    fetchPolicy: 'network-only',
  });

  // Build initialValues using useMemo so that they are recalculated only when needed.
  const initialValues: CreateEventInput = useMemo(() => {
    if (copiedEventId && eventData?.event) {
      const { event } = eventData;
      // Destructure and omit fields that are not part of CreateEventInput.
      const {
        id,
        createdAt,
        updatedAt,
        organizer,
        staff,
        totalBookings,
        status,
        startDate,
        endDate,
        __typename,
        ...rest
      } = event;
      return {
        ...rest,
        // Clear the dates so that the organizer must provide new ones.
        startDate: null,
        endDate: null,
        staffIds: [],
      } as CreateEventInput;
    }
    // If no copiedEventId, return blank defaults.
    return {
      title: '',
      description: '',
      location: '',
      country: '',
      state: '',
      city: '',
      zipCode: '',
      streetAddress: '',
      timezone: 'Europe/Bratislava',
      staffIds: [],
      arrivalLocation: '',
      howToGetThere: '',
      startDate: new Date(),
      endDate: new Date(),
      capacity: 1,
      price: 0,
      images: [],
      tags: [],
    };
  }, [copiedEventId, eventData]);

  const [createEvent, { loading, error }] = useMutation(CREATE_EVENT_MUTATION, {
    refetchQueries: [{ query: EVENT_QUERY }],
  });

  const handleSubmit = async (values: CreateEventInput) => {
    const payload = { ...values };
    const response = await createEvent({ variables: { input: payload } });
    const eventId = response.data.createEvent.id;
    navigate(`/events/${eventId}`);
  };

  if (copiedEventId && eventLoading) {
    return (
      <Box textAlign="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }
  if (copiedEventId && eventError) {
    return <Alert severity="error">{eventError.message}</Alert>;
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        {copiedEventId ? 'Copy Event & Create New Event' : 'Create New Event'}
      </Typography>
      {error && <Alert severity="error">{error.message}</Alert>}
      {loading ? (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <EventForm initialValues={initialValues} onSubmit={handleSubmit} isSubmitting={loading} />
      )}
    </Container>
  );
};

export default CreateEventPage;
