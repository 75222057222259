import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Box,
  Snackbar,
  Alert,
  SnackbarProps,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { BOOK_EVENT_MUTATION } from '../../graphql/events';
import { UPDATE_USER_MUTATION } from '../../graphql/users';
import { Event, EventBooking, UpdateUserInput, User } from '../../generated/graphql';
import { useAuth } from '../../contexts/AuthContext';

// Validation schema for updating the user's profile.
const userProfileSchema = Yup.object().shape({
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  phone: Yup.string().required('Phone number is required'),
  contactAddress: Yup.string().required('Contact address is required'),
});

interface RequestEventBookingModalProps {
  open: boolean;
  event: Event;
  onClose: () => void;
  onBookingSuccess?: () => void;
}

const RequestEventBookingModal: React.FC<RequestEventBookingModalProps> = ({
  open,
  event,
  onClose,
  onBookingSuccess,
}) => {
  const { user } = useAuth();

  // Mutation to update the user's profile.
  const [updateUser] = useMutation<{ updateUser: User }, { data: UpdateUserInput }>(UPDATE_USER_MUTATION);

  // Mutation to book the event.
  const [bookEvent, { loading: bookingLoading, error: bookingError }] = useMutation<
    { bookEvent: EventBooking },
    { eventId: string }
  >(BOOK_EVENT_MUTATION);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  // onClose handler for the Snackbar; it receives both event and reason.
  const handleSnackbarClose: SnackbarProps['onClose'] = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  // Check if the user's basic profile details are complete.
  const isProfileComplete = user && user.firstname && user.lastname && user.phone && user.contactAddress;

  // Formik for updating user profile if incomplete.
  const formik = useFormik({
    initialValues: {
      firstname: user?.firstname || '',
      lastname: user?.lastname || '',
      phone: user?.phone || '',
      contactAddress: user?.contactAddress || '',
    },
    validationSchema: userProfileSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await updateUser({ variables: { data: values } });
        setSnackbarMsg('Profile updated successfully.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        // After updating profile, proceed to book the event.
        await handleBooking();
      } catch (error: any) {
        console.error('Profile update failed:', error);
        setSnackbarMsg(error.message || 'Failed to update profile');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
      setSubmitting(false);
    },
    enableReinitialize: true,
  });

  // Function to perform the booking mutation.
  const handleBooking = async () => {
    try {
      await bookEvent({ variables: { eventId: event.id } });
      setSnackbarMsg('Booking request successful!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      if (onBookingSuccess) {
        onBookingSuccess();
      }
      // Delay closing the modal so that the Snackbar is visible.
      setTimeout(() => onClose(), 3000);
    } catch (error: any) {
      console.error('Booking event failed:', error);
      setSnackbarMsg(error.message || 'Booking request failed');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Handler when user clicks the Confirm button.
  const handleConfirm = async () => {
    if (!isProfileComplete) {
      // If profile is incomplete, submit the profile update form.
      formik.handleSubmit();
    } else {
      // Otherwise, directly perform the booking.
      await handleBooking();
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Reserve Your Spot</DialogTitle>
        <DialogContent>
          {!isProfileComplete ? (
            <>
              <Typography sx={{ mb: 2 }}>
                To reserve a spot for <strong>{event.title}</strong>, please complete your profile by providing your
                first name, last name, email, phone number, and contact address. You can change these details anytime in
                your profile.
              </Typography>
              <Box component="form" onSubmit={formik.handleSubmit} noValidate>
                <TextField
                  fullWidth
                  margin="dense"
                  id="firstname"
                  name="firstname"
                  label="First Name"
                  value={formik.values.firstname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                  helperText={formik.touched.firstname && formik.errors.firstname}
                />
                <TextField
                  fullWidth
                  margin="dense"
                  id="lastname"
                  name="lastname"
                  label="Last Name"
                  value={formik.values.lastname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                  helperText={formik.touched.lastname && formik.errors.lastname}
                />
                <TextField
                  fullWidth
                  margin="dense"
                  id="phone"
                  name="phone"
                  label="Phone Number"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
                <TextField
                  fullWidth
                  margin="dense"
                  id="contactAddress"
                  name="contactAddress"
                  label="Contact Address"
                  value={formik.values.contactAddress}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.contactAddress && Boolean(formik.errors.contactAddress)}
                  helperText={formik.touched.contactAddress && formik.errors.contactAddress}
                />
              </Box>
            </>
          ) : (
            <Typography>
              Confirm your booking request for <strong>{event.title}</strong>. Your profile details are complete.
            </Typography>
          )}
          {bookingError && (
            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
              {bookingError.message}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary" disabled={bookingLoading || formik.isSubmitting}>
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            color="primary"
            disabled={bookingLoading || formik.isSubmitting}
          >
            {bookingLoading || formik.isSubmitting
              ? 'Processing...'
              : !isProfileComplete
                ? 'Update Profile & Reserve'
                : 'Reserve Spot'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={(e) => handleSnackbarClose(e, 'timeout')} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RequestEventBookingModal;
