import { gql } from '@apollo/client';

export const TRIP_REVIEWS_QUERY = gql`
  query TripReviews($tripId: String!) {
    tripReviews(tripId: $tripId) {
      id
      overallRating
      qualityOfAccommodation
      qualityOfSurfLessons
      timingAccuracy
      organizationRating
      destinationRating
      funRating
      valueForMoneyRating
      safetyRating
      guidesOverallRating
      foodRating
      activitiesRating
      publicComment
      extraInformationPositive
      extraInformationNegative
      createdAt
      updatedAt
      user {
        id
        firstname
        lastname
        imageUrl
      }
      trip {
        id
        title
        startDate
        endDate
        images
      }
    }
  }
`;

export const TRIP_REVIEW_STATS_FOR_USER_QUERY = gql`
  query TripReviewStatsForUser($organizerId: String!, $first: Int, $after: String) {
    tripReviewStatsForUser(organizerId: $organizerId, first: $first, after: $after) {
      totalCount
      edges {
        cursor
        node {
          averageOverall
          averageAccommodation
          averageSurfLessons
          averageTiming
          reviewCount
          trip {
            id
            title
            images
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const GUIDE_REVIEW_STATS_FOR_USER_QUERY = gql`
  query GuideReviewStatsForUser($guideId: String!, $first: Int, $after: String) {
    guideReviewStatsForUser(guideId: $guideId, first: $first, after: $after) {
      totalCount
      edges {
        cursor
        node {
          averageOverall
          averageCommunication
          averageApproach
          reviewCount
          reviewCount
          trip {
            id
            title
            images
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
