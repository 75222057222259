import countryList from 'country-list';

const code2NameMap: Record<string, string> = countryList.getData().reduce(
  (acc, item) => {
    acc[item.code] = item.name;
    return acc;
  },
  {} as Record<string, string>
);

// fallback to code if we don't find it in map.. (e.g. code is something invalid or empty)
export function getCountryName(code: string | null | undefined): string {
  if (!code) return '';
  return code2NameMap[code] ?? code;
}
