import React, { useState, useMemo, MouseEvent } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  CircularProgress,
  Alert,
  Tabs,
  Tab,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { PRIVATE_EVENTS_QUERY, PUBLISH_EVENT, CANCEL_EVENT_BY_ORGANIZER } from '../../graphql/events';
import {
  Event,
  EventOrderField,
  EventStatusEnum,
  OrderDirection,
  PrivateEventsQuery,
  PrivateEventsQueryVariables,
  PublishEventMutation,
  PublishEventMutationVariables,
  CancelEventByOrganizerMutation,
  CancelEventByOrganizerMutationVariables,
  Role,
  SearchEventFilter,
} from '../../generated/graphql';
import { useAuth } from '../../contexts/AuthContext';

const OrganizerEventManagementPage: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Tabs: 0 = Upcoming, 1 = Past
  const [tabIndex, setTabIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');

  // Cancellation modal state
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [selectedEventForCancel, setSelectedEventForCancel] = useState<Event | null>(null);
  const [cancelReason, setCancelReason] = useState('');

  // More actions menu state for "Copy and Create Event"
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedEventForCopy, setSelectedEventForCopy] = useState<Event | null>(null);

  // Build filter based on tab and search query.
  const filter: SearchEventFilter = useMemo(() => {
    const currentTime = new Date();
    const baseFilter: SearchEventFilter = { query: searchQuery };
    if (tabIndex === 0) {
      // Upcoming: events with startDate >= now
      baseFilter.startDateFrom = currentTime;
    } else {
      // Past: events with startDate before now.
      baseFilter.startDateTo = currentTime;
    }
    return baseFilter;
  }, [tabIndex, searchQuery]);

  const { data, loading, error, fetchMore, refetch } = useQuery<PrivateEventsQuery, PrivateEventsQueryVariables>(
    PRIVATE_EVENTS_QUERY,
    {
      variables: {
        filter,
        first: 10,
        orderBy: { field: EventOrderField.StartDate, direction: OrderDirection.Asc },
      },
      fetchPolicy: 'network-only',
    }
  );

  const events: Event[] = data?.privateEvents?.edges?.map((edge) => edge.node as Event) || [];
  const pageInfo = data?.privateEvents?.pageInfo;

  const [publishEventMutation, { loading: publishLoading }] = useMutation<
    PublishEventMutation,
    PublishEventMutationVariables
  >(PUBLISH_EVENT, { onCompleted: () => refetch() });

  const [cancelEventMutation, { loading: cancelLoading }] = useMutation<
    CancelEventByOrganizerMutation,
    CancelEventByOrganizerMutationVariables
  >(CANCEL_EVENT_BY_ORGANIZER, { onCompleted: () => refetch() });

  // --- Handlers ---
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handlePublish = async (eventId: string) => {
    try {
      await publishEventMutation({ variables: { eventId } });
    } catch (err) {
      console.error('Error publishing event:', err);
    }
  };

  const handleEdit = (eventId: string) => {
    navigate(`/events/${eventId}/edit`);
  };

  const handleDetail = (eventId: string) => {
    navigate(`/events/${eventId}/organizer-detail`);
  };

  const handleCreate = () => {
    navigate('/events/create');
  };

  // Cancel event handlers
  const handleOpenCancelDialog = (event: Event) => {
    setSelectedEventForCancel(event);
    setCancelDialogOpen(true);
  };
  const handleCloseCancelDialog = () => {
    setCancelDialogOpen(false);
    setSelectedEventForCancel(null);
    setCancelReason('');
  };
  const handleConfirmCancel = async () => {
    if (!selectedEventForCancel) return;
    try {
      await cancelEventMutation({
        variables: { eventId: selectedEventForCancel.id, reason: cancelReason },
      });
      handleCloseCancelDialog();
    } catch (err) {
      console.error('Error cancelling event:', err);
    }
  };

  // More actions (copy event) menu handlers
  const handleMenuOpen = (e: MouseEvent<HTMLButtonElement>, event: Event) => {
    setMenuAnchorEl(e.currentTarget);
    setSelectedEventForCopy(event);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedEventForCopy(null);
  };
  const handleCopyEvent = () => {
    if (selectedEventForCopy) {
      navigate('/events/create', { state: { copiedEventId: selectedEventForCopy.id } });
    }
    handleMenuClose();
  };

  const handleLoadMore = () => {
    if (pageInfo?.hasNextPage) {
      fetchMore({
        variables: { after: pageInfo.endCursor },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prevResult;
          return {
            privateEvents: {
              __typename: prevResult.privateEvents.__typename,
              totalCount: fetchMoreResult.privateEvents.totalCount,
              edges: [...(prevResult.privateEvents.edges || []), ...(fetchMoreResult.privateEvents.edges || [])],
              pageInfo: fetchMoreResult.privateEvents.pageInfo,
            },
          };
        },
      });
    }
  };

  if (loading) {
    return (
      <Box textAlign="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography variant="h4">Event Management Dashboard</Typography>
        <Button variant="contained" color="primary" onClick={handleCreate} sx={{ mt: isMobile ? 2 : 0 }}>
          Create New Event
        </Button>
      </Box>

      {/* Search and Tabs */}
      <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center" gap={2} mb={3}>
        <Tabs value={tabIndex} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
          <Tab label="Upcoming Events" />
          <Tab label="Past Events" />
        </Tabs>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{ ml: isMobile ? 0 : 'auto', width: isMobile ? '100%' : 250 }}
        />
      </Box>

      {events.length === 0 ? (
        <Alert severity="info">No events found.</Alert>
      ) : (
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events.map((event) => (
                <TableRow key={event.id}>
                  <TableCell>
                    <Button variant="text" onClick={() => handleDetail(event.id)}>
                      {event.title}
                    </Button>
                  </TableCell>
                  <TableCell>
                    {DateTime.fromISO(event.startDate.toString()).toLocaleString(DateTime.DATE_MED)}
                  </TableCell>
                  <TableCell>{event.location}</TableCell>
                  <TableCell>{event.status || '-'}</TableCell>
                  <TableCell>
                    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={1} alignItems="center">
                      <Button variant="outlined" size="small" onClick={() => handleEdit(event.id)}>
                        Edit
                      </Button>
                      {event.status === EventStatusEnum.Initiated && user?.roles.includes(Role.Admin) && (
                        <Button
                          variant="contained"
                          color="success"
                          size="small"
                          onClick={() => handlePublish(event.id)}
                          disabled={publishLoading}
                        >
                          Publish
                        </Button>
                      )}
                      {event.status === EventStatusEnum.Active && (
                        <Button
                          variant="contained"
                          color="error"
                          size="small"
                          onClick={() => handleOpenCancelDialog(event)}
                        >
                          Cancel
                        </Button>
                      )}
                      {/* More actions: three-dots menu for Copy */}
                      <IconButton size="small" onClick={(e: MouseEvent<HTMLButtonElement>) => handleMenuOpen(e, event)}>
                        <MoreVertIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}

      {pageInfo?.hasNextPage && (
        <Box textAlign="center" mt={3}>
          <Button variant="contained" onClick={handleLoadMore} sx={{ borderRadius: 3 }}>
            Load More
          </Button>
        </Box>
      )}

      {/* Cancellation Modal */}
      <Dialog open={cancelDialogOpen} onClose={handleCloseCancelDialog} fullWidth maxWidth="sm">
        <DialogTitle>Cancel Event</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to cancel the event "{selectedEventForCancel?.title}"? Please provide a reason:
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            label="Cancellation Reason"
            type="text"
            fullWidth
            variant="outlined"
            value={cancelReason}
            onChange={(e) => setCancelReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCancelDialog}>Close</Button>
          <Button variant="contained" color="error" onClick={handleConfirmCancel} disabled={cancelLoading}>
            Confirm Cancellation
          </Button>
        </DialogActions>
      </Dialog>

      {/* More Actions Menu (Copy Event) */}
      <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={handleCopyEvent}>Copy and Create Event</MenuItem>
      </Menu>
    </Container>
  );
};

export default OrganizerEventManagementPage;
