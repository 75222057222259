import React from 'react';
import { Avatar, Box, Card, CardContent, Divider, Grid2, Typography, useTheme } from '@mui/material';
import { PublicUser } from '../../generated/graphql';
import { Link } from 'react-router-dom';

interface ParticipantInformationProps {
  participants?: PublicUser[] | null;
}

const ParticipantInformation: React.FC<ParticipantInformationProps> = ({ participants }) => {
  const theme = useTheme();

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Participants
      </Typography>
      <Divider sx={{ mb: 2 }} />
      {participants && participants.length > 0 ? (
        <Grid2 container spacing={2}>
          {participants.map((participant) => (
            <Grid2 size={{ xs: 12, md: 6 }} key={participant.id}>
              <Link to={`/user/${participant.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Card
                  variant="outlined"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 2,
                    boxShadow: theme.shadows[1],
                    borderRadius: 2,
                    transition: 'transform 0.2s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.02)',
                      boxShadow: theme.shadows[4],
                    },
                  }}
                >
                  <Avatar src={participant.imageUrl || ''} sx={{ width: 56, height: 56, mr: 2 }} />
                  <CardContent sx={{ p: 0 }}>
                    <Typography variant="subtitle1" noWrap>
                      {participant.firstname} {participant.lastname}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" noWrap>
                      {participant.email}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid2>
          ))}
        </Grid2>
      ) : (
        <Typography variant="body2" color="textSecondary">
          No participants found.
        </Typography>
      )}
    </Box>
  );
};

export default ParticipantInformation;
