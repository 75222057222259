import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Avatar,
  TextField,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Divider,
  useTheme,
  Link,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import {
  ParticipantStatusEnum,
  Trip,
  TripParticipant,
  LoyaltyLevel,
  AddManualInvoiceMutation,
  AddManualInvoiceMutationVariables,
  InvoiceTypeEnum,
} from '../../../generated/graphql';
import { ADD_MANUAL_INVOICE_MUTATION, REFRESH_INVOICES_MUTATION } from '../../../graphql/trip-invoice';
import { TRIP_PARTICIPANTS_QUERY } from '../../../graphql/trip-participants';

const getLoyaltyDiscount = (level: LoyaltyLevel): number => {
  switch (level) {
    case LoyaltyLevel.Level_1:
      return 0.05;
    case LoyaltyLevel.Level_2:
      return 0.08;
    case LoyaltyLevel.Level_3:
      return 0.1;
    case LoyaltyLevel.Level_4:
      return 0.15;
    default:
      return 0;
  }
};

interface ParticipantDetailModalProps {
  open: boolean;
  onClose: () => void;
  participant: TripParticipant;
  trip: Trip;
  userIsAdmin: boolean;
  onAccept: (userId: string, finalPrice?: number) => void;
  onReject: (userId: string) => void;
  onCancel: (userId: string) => void;
  onDelete: (participantId: string) => void;
}

const ParticipantDetailModal: React.FC<ParticipantDetailModalProps> = ({
  open,
  onClose,
  participant,
  trip,
  userIsAdmin,
  onAccept,
  onReject,
  onCancel,
  onDelete,
}) => {
  const theme = useTheme();
  const displayName = `${participant.user?.firstname ?? ''} ${participant.user?.lastname ?? ''}`;
  const phone = participant.user?.phone ?? '';
  const email = participant.user?.email ?? '';
  const address = participant.user?.contactAddress ?? '';
  const status = participant.status;

  const [acceptLoading, setAcceptLoading] = useState<boolean>(false);
  const [localFinalPrice, setLocalFinalPrice] = useState<number>(
    participant.finalPrice ??
      Math.floor(trip.price * (1 - getLoyaltyDiscount(participant.user?.level as LoyaltyLevel))) ??
      0
  );
  // NEW: localInvoices state for the invoices
  const [localInvoices, setLocalInvoices] = useState(participant.invoices || []);

  // Update local final price and invoices when participant prop changes
  useEffect(() => {
    setLocalFinalPrice(
      participant.finalPrice ??
        Math.floor(trip.price * (1 - getLoyaltyDiscount(participant.user?.level as LoyaltyLevel))) ??
        0
    );
    setLocalInvoices(participant.invoices || []);
  }, [participant, trip]);

  const totalInvoicePaid = localInvoices?.reduce((acc, inv) => (inv.isPaid ? acc + inv.amount : acc), 0) || 0;
  const totalInvoiceAmount = localInvoices?.reduce((acc, inv) => acc + inv.amount, 0) || 0;

  // --- Manual Invoice Management State ---
  const [newManualInvoiceId, setNewManualInvoiceId] = useState('');
  const [newManualInvoiceType, setNewManualInvoiceType] = useState<InvoiceTypeEnum>(InvoiceTypeEnum.Proforma);
  const [manualInvoiceLoading, setManualInvoiceLoading] = useState(false);
  const [refreshInvoicesLoading, setRefreshInvoicesLoading] = useState(false);

  // Both mutations refetch the TRIP_PARTICIPANTS_QUERY.
  // Here we use the mutation response to update localInvoices as well.
  const [addManualInvoice] = useMutation<AddManualInvoiceMutation, AddManualInvoiceMutationVariables>(
    ADD_MANUAL_INVOICE_MUTATION,
    {
      refetchQueries: [{ query: TRIP_PARTICIPANTS_QUERY, variables: { tripId: trip.id, first: 100 } }],
      onCompleted: (data) => {
        // Assume backend returns the added invoice.
        if (data && data.addManualInvoice) {
          // Append the new invoice to localInvoices.
          setLocalInvoices((prev) => [
            ...prev,
            {
              ...data.addManualInvoice,
              createdAt: new Date().toISOString(),
              tripId: trip.id,
              updatedAt: new Date().toISOString(),
              userId: participant.userId,
            },
          ]);
        }
      },
    }
  );
  const [refreshInvoices] = useMutation(REFRESH_INVOICES_MUTATION, {
    refetchQueries: [{ query: TRIP_PARTICIPANTS_QUERY, variables: { tripId: trip.id, first: 100 } }],
    onCompleted: (data) => {
      // Assume backend returns the full updated invoice list.
      if (data && data.refreshTripInvoices) {
        setLocalInvoices(data.refreshTripInvoices);
      }
    },
  });

  const handleAddManualInvoice = async () => {
    if (!newManualInvoiceId) return;
    setManualInvoiceLoading(true);
    try {
      await addManualInvoice({
        variables: {
          input: {
            invoiceId: newManualInvoiceId,
            tripParticipantId: participant.id,
            invoiceType: newManualInvoiceType,
          },
        },
      });
      setNewManualInvoiceId('');
    } catch (error) {
      console.error('Error adding manual invoice:', error);
    } finally {
      setManualInvoiceLoading(false);
    }
  };

  const handleRefreshInvoices = async () => {
    setRefreshInvoicesLoading(true);
    try {
      await refreshInvoices({
        variables: { tripParticipantId: participant.id },
      });
    } catch (error) {
      console.error('Error refreshing invoices:', error);
    } finally {
      setRefreshInvoicesLoading(false);
    }
  };

  // --- End Manual Invoice Management ---

  const handleAcceptClick = async () => {
    setAcceptLoading(true);
    try {
      await onAccept(participant.userId, localFinalPrice);
      onClose();
    } catch (err) {
      console.error(err);
    } finally {
      setAcceptLoading(false);
    }
  };

  const handleRejectClick = async () => {
    await onReject(participant.userId);
    onClose();
  };

  const handleCancelClick = async () => {
    if (
      !window.confirm(
        `This participant has invoices totaling €${totalInvoicePaid.toFixed(
          2
        )} paid. Please ensure you have processed any necessary refunds before confirming the cancellation. Continue?`
      )
    ) {
      return;
    }
    await onCancel(participant.userId);
    onClose();
  };

  const handleDeleteClick = async () => {
    await onDelete(participant.userId);
    onClose();
  };

  const getStatusDisplay = (status: ParticipantStatusEnum) => {
    switch (status) {
      case ParticipantStatusEnum.CancelledByParticipant:
        return 'Cancellation Requested';
      default:
        return status;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" PaperProps={{ sx: { borderRadius: 3 } }}>
      <DialogTitle sx={{ pb: 1, fontWeight: 'bold', color: theme.palette.primary.dark }}>
        Manage Participant
      </DialogTitle>
      <DialogContent dividers sx={{ pt: 2 }}>
        <Box display="flex" alignItems="center" gap={2} mb={3}>
          <Avatar
            src={participant.user?.imageUrl || '/images/default_avatar.png'}
            alt={displayName}
            sx={{ width: 64, height: 64, border: `2px solid ${theme.palette.primary.light}` }}
          />
          <Box>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {displayName}
            </Typography>
            <Typography variant="body2">Phone: {phone}</Typography>
            <Typography variant="body2">Email: {email}</Typography>
            {address && <Typography variant="body2">Address: {address}</Typography>}
            <Typography variant="body2" sx={{ mt: 1, fontWeight: 'bold', color: 'text.secondary' }}>
              Status: {getStatusDisplay(status)}
            </Typography>
          </Box>
        </Box>

        {status === ParticipantStatusEnum.Pending && (
          <Box>
            {participant.motivation && (
              <Box mb={2}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                  Motivation
                </Typography>
                <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                  {participant.motivation}
                </Typography>
              </Box>
            )}
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 2, mb: 1.5 }}>
              Price Details
            </Typography>
            <Box
              sx={{
                backgroundColor: theme.palette.grey[50],
                borderRadius: 1,
                p: 2,
                border: `1px solid ${theme.palette.grey[200]}`,
              }}
            >
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography variant="body2" color="text.secondary">
                  Base Trip Price:
                </Typography>
                <Typography variant="body2" fontWeight="medium">
                  €{trip.price}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography variant="body2" color="text.secondary">
                  Loyalty Level:
                </Typography>
                <Typography variant="body2" fontWeight="medium">
                  {participant.user?.level?.replace('_', ' ') || 'LEVEL 0'}
                  {getLoyaltyDiscount(participant.user?.level as LoyaltyLevel) > 0 && (
                    <Typography component="span" color="success.main" sx={{ ml: 1 }}>
                      (-{(getLoyaltyDiscount(participant.user?.level as LoyaltyLevel) * 100).toFixed(0)}%)
                    </Typography>
                  )}
                </Typography>
              </Box>
              <Divider sx={{ my: 1.5 }} />
              <Box display="flex" justifyContent="space-between">
                <Typography variant="subtitle2" fontWeight="bold">
                  Suggested Price:
                </Typography>
                <Typography variant="subtitle2" fontWeight="bold" color="primary.main">
                  €{(trip.price * (1 - getLoyaltyDiscount(participant.user?.level as LoyaltyLevel))).toFixed(2)}
                </Typography>
              </Box>
            </Box>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 0.5, mt: 2 }}>
              Set Final Price
            </Typography>
            <TextField
              type="number"
              size="small"
              value={localFinalPrice}
              onChange={(e) => setLocalFinalPrice(parseFloat(e.target.value))}
              sx={{ width: 130 }}
            />
            <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
              Once you accept this participant, the final price is locked in.
            </Typography>
            <Box display="flex" gap={2} mt={3}>
              <Button
                variant="contained"
                onClick={handleAcceptClick}
                disabled={acceptLoading}
                sx={{ position: 'relative' }}
              >
                {acceptLoading ? <CircularProgress size={24} color="inherit" /> : 'Accept'}
              </Button>
              <Button variant="outlined" color="warning" onClick={handleRejectClick}>
                Reject
              </Button>
            </Box>
          </Box>
        )}

        {status === ParticipantStatusEnum.Approved && (
          <>
            <Box mb={2}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Final Price: €{localFinalPrice}
              </Typography>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Invoices Paid: €{totalInvoicePaid.toFixed(2)} / Total Invoices: €{totalInvoiceAmount.toFixed(2)}
              </Typography>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>
              Invoices
            </Typography>
            {localInvoices && localInvoices.length > 0 ? (
              <Table size="small" sx={{ mb: 2 }}>
                <TableHead>
                  <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
                    <TableCell sx={{ fontWeight: 'bold' }}>Invoice #</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Type</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Amount</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Due Date</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {localInvoices.map((inv) => (
                    <TableRow key={inv.invoiceId}>
                      <TableCell>{inv.invoiceNumber || inv.invoiceId}</TableCell>
                      <TableCell>{inv.tripInvoiceType}</TableCell>
                      <TableCell>€{inv.amount.toFixed(2)}</TableCell>
                      <TableCell>{new Date(inv.dueDate).toLocaleDateString()}</TableCell>
                      <TableCell>{inv.isPaid ? 'Paid' : 'Pending'}</TableCell>
                      <TableCell>
                        {inv.invoiceUrl && (
                          <Link href={inv.invoiceUrl} target="_blank" rel="noopener">
                            View
                          </Link>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Typography variant="body2" sx={{ mb: 2 }}>
                No invoices available.
              </Typography>
            )}

            {/* --- Manual Invoice Management Section --- */}
            <Box mt={3} p={2} border="1px solid" borderColor="grey.300" borderRadius={2}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Manual Invoice Management
              </Typography>
              <Box display="flex" alignItems="center" gap={2} mb={2}>
                <TextField
                  label="SuperFaktura Invoice ID"
                  variant="outlined"
                  size="small"
                  value={newManualInvoiceId}
                  onChange={(e) => setNewManualInvoiceId(e.target.value)}
                />
                <TextField
                  select
                  label="Invoice Type"
                  variant="outlined"
                  size="small"
                  value={newManualInvoiceType}
                  onChange={(e) => setNewManualInvoiceType(e.target.value as InvoiceTypeEnum)}
                  style={{ minWidth: 120 }}
                >
                  <MenuItem value="PROFORMA">Proforma</MenuItem>
                  <MenuItem value="FINAL">Final</MenuItem>
                </TextField>
                <Button variant="contained" onClick={handleAddManualInvoice} disabled={manualInvoiceLoading}>
                  {manualInvoiceLoading ? <CircularProgress size={24} color="inherit" /> : 'Add Manual Invoice'}
                </Button>
              </Box>
              <Button variant="outlined" onClick={handleRefreshInvoices} disabled={refreshInvoicesLoading}>
                {refreshInvoicesLoading ? <CircularProgress size={24} color="inherit" /> : 'Refresh Invoices'}
              </Button>
            </Box>
            {/* --- End Manual Invoice Management Section --- */}

            <Button variant="outlined" color="error" sx={{ mt: 2 }} onClick={handleCancelClick}>
              Cancel This Participant
            </Button>
          </>
        )}

        {(status === ParticipantStatusEnum.Cancelled || status === ParticipantStatusEnum.Rejected) && userIsAdmin && (
          <>
            <Divider sx={{ my: 2 }} />
            <Box>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                This participant is cancelled. You can permanently delete them (Admin only).
              </Typography>
              <Button variant="contained" color="error" onClick={handleDeleteClick}>
                Permanently Delete
              </Button>
            </Box>
          </>
        )}

        {status === ParticipantStatusEnum.CancelledByParticipant && (
          <Box sx={{ mt: 2, p: 2, bgcolor: 'warning.light', borderRadius: 1 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'warning.dark' }}>
              Cancellation Requested by Participant
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1, mb: 2 }}>
              This participant has requested cancellation. Please review any necessary refunds and confirm cancellation
              below.
            </Typography>
            <Button variant="contained" color="warning" onClick={handleCancelClick}>
              Confirm Cancellation
            </Button>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ParticipantDetailModal;
