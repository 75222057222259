import React from 'react';
import { Box, Typography, Divider, Button } from '@mui/material';
import { DateTime } from 'luxon';
import { CancellationPolicy } from '../../generated/graphql';

interface CancellationDetailsProps {
  cancellationPolicy: CancellationPolicy | null;
  tripPrice: number;
  startDate: string;
  onCancel: () => void;
}

const CancellationDetails: React.FC<CancellationDetailsProps> = ({
  cancellationPolicy,
  tripPrice,
  startDate,
  onCancel,
}) => {
  const today = DateTime.now();
  const tripStart = DateTime.fromISO(startDate);
  const daysBefore = Math.floor(tripStart.diff(today, 'days').days);

  // Determine the applicable cancellation rule.
  let applicableRule = null;
  if (cancellationPolicy && cancellationPolicy.rules?.length) {
    // Sort rules descending by daysBeforeStart.
    const sortedRules = [...cancellationPolicy.rules].sort((a, b) => b.daysBeforeStart - a.daysBeforeStart);
    applicableRule =
      sortedRules.find((rule) => daysBefore >= rule.daysBeforeStart) || sortedRules[sortedRules.length - 1];
  }
  const baseFee = cancellationPolicy?.baseFee || 0;
  const refundPercentage = applicableRule ? applicableRule.refundPercentage : 0;
  // Calculate refund amount: assume refund applies to (tripPrice - baseFee)
  const refundAmount = Math.max((tripPrice - baseFee) * (refundPercentage / 100), 0);
  const nonRefundable = tripPrice - refundAmount;

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Cancellation Policy
      </Typography>
      <Divider sx={{ mb: 2 }} />
      {cancellationPolicy ? (
        <Box>
          <Typography variant="body2">
            Base Non-Refundable Fee: <strong>€{baseFee}</strong>
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            If you cancel {daysBefore} days before the start, you'll receive a refund of{' '}
            <strong>{refundPercentage}%</strong>
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Refund Amount: <strong>€{refundAmount.toFixed(2)}</strong>
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Amount that will not be returned: <strong>€{nonRefundable.toFixed(2)}</strong>
          </Typography>
          {cancellationPolicy.note && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              Note: {cancellationPolicy.note}
            </Typography>
          )}
          <Button variant="contained" color="error" sx={{ mt: 2, textTransform: 'none' }} onClick={onCancel}>
            Cancel Reservation
          </Button>
        </Box>
      ) : (
        <Typography variant="body2">Cancellation policy is not available for this trip.</Typography>
      )}
    </Box>
  );
};

export default CancellationDetails;
