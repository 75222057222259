import React from 'react';
import { Box, Divider, Typography, Stack } from '@mui/material';
import { ExtraInfo } from '../../generated/graphql';

import CardTravel from '@mui/icons-material/CardTravel';
import Info from '@mui/icons-material/Info';
import Payments from '@mui/icons-material/Payments';
import Vaccines from '@mui/icons-material/Vaccines';
import WbSunny from '@mui/icons-material/WbSunny';
import ApprovalOutlined from '@mui/icons-material/ApprovalOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PassportIcon from '../TripDetail/Icons/PassportIcon';
import TimezoneIcon from '../TripDetail/Icons/TimezoneIcon';

interface ReservationAdditionalInformationProps {
  extraInformation: ExtraInfo[];
}

const iconMapping: { [key: string]: React.ReactElement } = {
  'visa info': <ApprovalOutlined fontSize="large" />,
  'travel insurance': <CardTravel fontSize="large" />,
  'passport info': <PassportIcon size={34} />,
  vaccination: <Vaccines fontSize="large" />,
  money: <Payments fontSize="large" />,
  'time zone': <TimezoneIcon size={34} />,
  weather: <WbSunny fontSize="large" />,
  'extra info': <Info fontSize="large" />,
};

const ReservationAdditionalInformation: React.FC<ReservationAdditionalInformationProps> = ({ extraInformation }) => {
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6" gutterBottom>
        Additional Information
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Stack spacing={2}>
        {extraInformation && extraInformation.length > 0 ? (
          extraInformation.map((info) => {
            // Lookup icon from mapping based on lower-case title. Fallback to HelpOutlineIcon if not found.
            const icon = iconMapping[info.title.toLowerCase()] || <HelpOutlineIcon fontSize="large" />;
            return (
              <Stack key={info.id} direction="row" spacing={2} alignItems="center">
                <Box sx={{ color: 'primary.main' }}>{icon}</Box>
                <Box>
                  <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                    {info.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {info.description}
                  </Typography>
                </Box>
              </Stack>
            );
          })
        ) : (
          <Typography variant="body2" color="textSecondary">
            No additional information provided.
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default ReservationAdditionalInformation;
