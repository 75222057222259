import { gql } from '@apollo/client';

export const CREATE_TRIP_REVIEW_MUTATION = gql`
  mutation CreateTripReview($input: CreateTripReviewInput!) {
    createTripReview(input: $input) {
      id
      createdAt
      overallRating
      publicComment
    }
  }
`;

export const CREATE_GUIDE_REVIEW_MUTATION = gql`
  mutation CreateGuideReview($input: CreateGuideReviewInput!) {
    createGuideReview(input: $input) {
      id
      createdAt
      overallRating
      publicComment
    }
  }
`;

export const GUIDE_REVIEWS_QUERY = gql`
  query GuideReviews(
    $guideId: String!
    $after: String
    $first: Int
    $last: Int
    $before: String
    $orderBy: GuideReviewOrder
    $skip: Int
    $tripId: String
  ) {
    guideReviews(
      guideId: $guideId
      after: $after
      first: $first
      last: $last
      before: $before
      orderBy: $orderBy
      skip: $skip
      tripId: $tripId
    ) {
      totalCount
      edges {
        cursor
        node {
          id
          createdAt
          updatedAt
          overallRating
          publicComment
          ratingCommunication
          organizationRating
          ratingApproach
          timingAccuracy
          funRating
          friendliness
          activitiesRating
          drivingSkills
          extraInformationPositive
          extraInformationNegative
          user {
            id
            firstname
            lastname
            imageUrl
          }
          trip {
            id
            title
            startDate
            endDate
            images
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
