import React from 'react';
import { Card, CardActions, CardContent, CardMedia, Chip, Button, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { TripParticipant, ParticipantStatusEnum } from '../../../../generated/graphql';

interface TripCardProps {
  participation: Partial<TripParticipant>;
}

export const TripCard: React.FC<TripCardProps> = ({ participation }) => {
  const navigate = useNavigate();
  const startDate = DateTime.fromISO(participation.trip?.startDate || '');
  const endDate = DateTime.fromISO(participation.trip?.endDate || '').toLocaleString(DateTime.DATE_MED);
  const dateRange = `${startDate.toLocaleString(DateTime.DATE_MED)} - ${endDate}`;
  const now = DateTime.now();
  const canCancel =
    participation.status &&
    [ParticipantStatusEnum.Pending, ParticipantStatusEnum.Approved].includes(participation.status) &&
    startDate > now;
  const endedAlready = DateTime.fromISO(participation.trip?.endDate || '') < now;
  const withinReviewPeriod = now.diff(DateTime.fromISO(participation.trip?.endDate || ''), 'days').days <= 30;
  const canReview =
    endedAlready &&
    withinReviewPeriod &&
    participation.status === ParticipantStatusEnum.Approved &&
    !participation.hasTripReview;

  return (
    <Card sx={{ maxWidth: 345, boxShadow: '0 1px 5px rgba(0, 0, 0, 0.1)' }}>
      <Box sx={{ position: 'relative' }}>
        <CardMedia
          image={participation.trip?.images?.[0] || '/images/trip-placeholder.jpg'}
          title={participation.trip?.title || 'Trip'}
          sx={{ height: 200 }}
        />
        {participation.hasTripReview ? (
          <Chip
            label="Reviewed"
            sx={{ position: 'absolute', top: 8, right: 8, backgroundColor: 'success.light', color: 'black' }}
          />
        ) : (
          <Chip
            label={participation.status}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              backgroundColor: 'primary.light',
              color: 'primary.dark',
            }}
          />
        )}
      </Box>
      <CardContent>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          {participation.trip?.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {dateRange}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {`${participation.trip?.continent}, ${participation.trip?.country}`}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {participation.trip?.price} EUR / person
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: 'space-between' }}>
        {canCancel && (
          <Button
            color="error"
            size="small"
            onClick={() => navigate(`/trips/${participation.trip?.id}/reservation-details?section=cancellation`)}
          >
            {participation.status === ParticipantStatusEnum.Pending ? 'Cancel Request' : 'Cancel Trip'}
          </Button>
        )}
        {canReview && (
          <Button
            color="secondary"
            size="small"
            sx={{ mr: 1 }}
            onClick={() => navigate(`/trip/${participation.trip?.id}/review`)}
          >
            Review Trip
          </Button>
        )}
        <Button
          size="small"
          color="secondary"
          onClick={() => navigate(`/trips/${participation.trip?.id}/reservation-details?section=summary`)}
        >
          View Trip
        </Button>
      </CardActions>
    </Card>
  );
};
