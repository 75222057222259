import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

interface RefundInfo {
  baseFee: number;
  refundPercentage: number;
  refundAmount: number;
  nonRefundable: number;
}

interface CancelReservationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  refundInfo: RefundInfo;
}

const CancelReservationModal: React.FC<CancelReservationModalProps> = ({ open, onClose, onConfirm, refundInfo }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Confirm Cancellation</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Are you sure you want to cancel this trip? The amount you have paid will only be returned to the account you
          used for payment.
        </Typography>
        <Typography variant="body2">
          Base Non-Refundable Fee: <strong>€{refundInfo.baseFee}</strong>
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          Refund Percentage: <strong>{refundInfo.refundPercentage}%</strong>
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          Refund Amount: <strong>€{refundInfo.refundAmount.toFixed(2)}</strong>
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          Amount that will not be returned: <strong>€{refundInfo.nonRefundable.toFixed(2)}</strong>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Keep My Reservation
        </Button>
        <Button onClick={onConfirm} variant="contained" color="error">
          Confirm Cancellation
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelReservationModal;
