import { Stack } from '@mui/material';
import { Trip } from '../../generated/graphql';
import { useTranslation } from 'react-i18next';
import IconText from './IconText';
import { getDaysBetweenDates } from '../../utils/utilityFunctions';

import GroupsIcon from '@mui/icons-material/Groups';
import EuroIcon from '@mui/icons-material/Euro';
import FmdGood from '@mui/icons-material/FmdGood';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ShakaIcon from './Icons/ShakaIcon';
import { getCountryName } from '../common/getCountryName';

interface TripSummaryProps {
  trip: Trip;
}

const TripSummary = ({ trip }: TripSummaryProps) => {
  const { t } = useTranslation();
  const friendlyCountryName = getCountryName(trip?.country);

  return (
    <Stack direction="row" gap={2} mb={5} mt={4} justifyContent="space-between" flexWrap="wrap">
      <IconText
        icon={<CalendarMonthIcon fontSize="medium" sx={{ color: 'white' }} />}
        text={`${getDaysBetweenDates(trip.startDate, trip.endDate)} ${t('days')}`}
      />
      <IconText
        icon={<GroupsIcon fontSize="medium" sx={{ color: 'white' }} />}
        text={`${trip.numberOfPeople} ${t('people')}`}
      />
      <IconText
        icon={<FmdGood fontSize="medium" sx={{ color: 'white' }} />}
        text={`${friendlyCountryName}, ${trip.continent}`}
      />
      <IconText icon={<EuroIcon fontSize="medium" sx={{ color: 'white' }} />} text={`${trip.price.toFixed(2)}`} />
      <IconText icon={<ShakaIcon size={23} />} text={t('beginner_friendly')} />
    </Stack>
  );
};

export default TripSummary;
