import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box, CircularProgress, Alert, Paper, Grid, Divider, Avatar } from '@mui/material';
import { useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import { TRIP_REVIEWS_QUERY } from '../../graphql/trip-reviews';
import { TripReviewsQuery, TripReviewsQueryVariables } from '../../generated/graphql';

const TripReviewsPage: React.FC = () => {
  const { tripId } = useParams<{ tripId: string }>();

  const { data, loading, error } = useQuery<TripReviewsQuery, TripReviewsQueryVariables>(TRIP_REVIEWS_QUERY, {
    variables: { tripId: tripId! },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return (
      <Box textAlign="center" mt={6}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return <Alert severity="error">Error loading reviews: {error.message}</Alert>;
  }

  const reviews = data?.tripReviews || [];

  return (
    <Container maxWidth="md" sx={{ mt: 6, mb: 6 }}>
      <Typography variant="h4" sx={{ fontWeight: 700, mb: 4 }}>
        Trip Reviews
      </Typography>
      {reviews.length === 0 ? (
        <Alert severity="info">No reviews found for this trip.</Alert>
      ) : (
        <Grid container spacing={3}>
          {reviews.map((review) => {
            const reviewDate = DateTime.fromISO(review.createdAt).toLocaleString(DateTime.DATE_MED);
            const tripStart = DateTime.fromISO(review.trip.startDate.toString()).toLocaleString(DateTime.DATE_MED);
            const tripEnd = DateTime.fromISO(review.trip.endDate.toString()).toLocaleString(DateTime.DATE_MED);
            return (
              <Grid item xs={12} key={review.id}>
                <Paper
                  elevation={2}
                  sx={{
                    p: 3,
                    borderRadius: 3,
                    transition: 'transform 0.2s ease-in-out',
                    '&:hover': { transform: 'scale(1.02)' },
                  }}
                >
                  {/* Reviewer Header */}
                  <Box display="flex" alignItems="center" gap={2} mb={2}>
                    <Avatar
                      src={review.user.imageUrl || '/images/default_avatar.png'}
                      alt={`${review.user.firstname} ${review.user.lastname}`}
                      sx={{ width: 48, height: 48 }}
                    />
                    <Box>
                      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {review.user.firstname} {review.user.lastname}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Overall Rating: {review.overallRating}/5
                      </Typography>
                    </Box>
                  </Box>

                  <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                    Reviewed on {reviewDate}
                  </Typography>
                  <Divider sx={{ my: 1 }} />

                  {/* Detailed Ratings */}
                  <Box sx={{ mb: 2 }}>
                    {review.qualityOfAccommodation !== null && (
                      <Typography variant="body2">
                        <strong>Accommodation:</strong> {review.qualityOfAccommodation}/5
                      </Typography>
                    )}
                    {review.qualityOfSurfLessons !== null && (
                      <Typography variant="body2">
                        <strong>Surf Lessons:</strong> {review.qualityOfSurfLessons}/5
                      </Typography>
                    )}
                    {review.timingAccuracy !== null && (
                      <Typography variant="body2">
                        <strong>Timing Accuracy:</strong> {review.timingAccuracy}/5
                      </Typography>
                    )}
                    {review.organizationRating !== null && (
                      <Typography variant="body2">
                        <strong>Organization:</strong> {review.organizationRating}/5
                      </Typography>
                    )}
                    {review.destinationRating !== null && (
                      <Typography variant="body2">
                        <strong>Destination:</strong> {review.destinationRating}/5
                      </Typography>
                    )}
                    {review.funRating !== null && (
                      <Typography variant="body2">
                        <strong>Fun:</strong> {review.funRating}/5
                      </Typography>
                    )}
                    {review.valueForMoneyRating !== null && (
                      <Typography variant="body2">
                        <strong>Value for Money:</strong> {review.valueForMoneyRating}/5
                      </Typography>
                    )}
                    {review.safetyRating !== null && (
                      <Typography variant="body2">
                        <strong>Safety:</strong> {review.safetyRating}/5
                      </Typography>
                    )}
                    {review.guidesOverallRating !== null && (
                      <Typography variant="body2">
                        <strong>Guide Overall:</strong> {review.guidesOverallRating}/5
                      </Typography>
                    )}
                    {review.foodRating !== null && (
                      <Typography variant="body2">
                        <strong>Food:</strong> {review.foodRating}/5
                      </Typography>
                    )}
                    {review.activitiesRating !== null && (
                      <Typography variant="body2">
                        <strong>Activities:</strong> {review.activitiesRating}/5
                      </Typography>
                    )}
                  </Box>

                  {/* Public Comments & Extra Info */}
                  {review.publicComment && (
                    <Typography variant="body1" sx={{ mb: 2 }}>
                      {review.publicComment}
                    </Typography>
                  )}
                  {review.extraInformationPositive && (
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      <strong>Pros:</strong> {review.extraInformationPositive}
                    </Typography>
                  )}
                  {review.extraInformationNegative && (
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      <strong>Cons:</strong> {review.extraInformationNegative}
                    </Typography>
                  )}

                  <Divider sx={{ my: 1 }} />

                  {/* Trip Information */}
                  <Typography variant="body2" color="text.secondary">
                    For trip: <strong>{review.trip.title}</strong> ({tripStart} – {tripEnd})
                  </Typography>
                  <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1 }}>
                    Updated at {DateTime.fromISO(review.updatedAt).toLocaleString(DateTime.DATETIME_MED)}
                  </Typography>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Container>
  );
};

export default TripReviewsPage;
