import React from 'react';
import { Avatar, Box, Button, Grid2, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { getCountryName } from '../common/getCountryName';
import { Trip } from '../../generated/graphql';
import { Link } from 'react-router-dom';

const ReservationSummary: React.FC<{ trip: Trip }> = ({ trip }) => {
  // Format start and end dates using Luxon
  const start = DateTime.fromISO(trip.startDate).toFormat('dd LLL yyyy');
  const end = DateTime.fromISO(trip.endDate).toFormat('dd LLL yyyy');

  // Calculate occupancy text
  const occupancy = `${trip.approvedParticipantsCount}/${trip.numberOfPeople} spots booked`;

  return (
    <Grid2 container spacing={2}>
      {/* Image Section */}
      <Grid2 size={{ xs: 12, md: 4 }} sx={{ textAlign: 'center' }}>
        {trip.images && trip.images.length > 0 && (
          <Box
            component="img"
            src={trip.images[0]}
            alt={trip.title}
            sx={{
              width: '100%',
              maxWidth: 200,
              borderRadius: 2,
              objectFit: 'cover',
            }}
          />
        )}
      </Grid2>

      {/* Trip Info Section */}
      <Grid2 size={{ xs: 12, md: 8 }}>
        <Typography variant="h6" gutterBottom>
          {trip.title}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {getCountryName(trip.country)}
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
          {start} – {end}
        </Typography>
        {trip.tripCategories && trip.tripCategories.length > 0 && (
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            Categories: {trip.tripCategories.join(', ')}
          </Typography>
        )}

        <Typography variant="body2" sx={{ mt: 1, fontWeight: 500 }}>
          Price: <strong>€{trip.price}</strong>
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
          {occupancy}
        </Typography>

        {/* Organizer Info */}
        {trip.organizer && (
          <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
            <Avatar src={trip.organizer.imageUrl || ''} sx={{ width: 40, height: 40, mr: 1 }} />
            <Typography variant="body2" color="textSecondary">
              Organized by{' '}
              <Link to={`/user/${trip.organizer.id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                {trip.organizer.firstname} {trip.organizer.lastname}
              </Link>
            </Typography>
          </Box>
        )}

        {/* Action Button */}
        <Box sx={{ mt: 3 }}>
          <Button variant="text" href={`/trip/${trip.id}`} sx={{ textTransform: 'none' }}>
            View Trip Details
          </Button>
        </Box>
      </Grid2>
    </Grid2>
  );
};

export default ReservationSummary;
