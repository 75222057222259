import { gql } from '@apollo/client';

export const ADD_MANUAL_INVOICE_MUTATION = gql`
  mutation AddManualInvoice($input: AddManualInvoiceInput!) {
    addManualInvoice(input: $input) {
      id
      invoiceId
      invoiceNumber
      amount
      dueDate
      isPaid
      paidAt
      invoiceUrl
      tripInvoiceType
    }
  }
`;

export const REFRESH_INVOICES_MUTATION = gql`
  mutation RefreshTripInvoices($tripParticipantId: String!) {
    refreshTripInvoices(tripParticipantId: $tripParticipantId) {
      id
      invoiceId
      invoiceNumber
      amount
      dueDate
      isPaid
      paidAt
      invoiceUrl
      tripInvoiceType
    }
  }
`;
